//import React from "react";
import { connect } from "react-redux";
//import './datepicker.scss';
import React, { PureComponent } from "react";
//import { Link } from "react-router-dom";
import * as myConstClass from '../../constant.js';
import $ from 'jquery';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Select from 'react-select';
import 'react-quill/dist/quill.bubble.css'
//import * as Icon from "react-feather";
import cryptoRandomString from 'crypto-random-string';
import {
  Navbar,
  NavbarBrand,
  Row, Col, Label, Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardLink,
  Container, Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  CardImg,
  Table,
  CustomInput
} from "reactstrap";


//import Header from "../../components/Header";
//import './common.scss';
import { toastr } from "react-redux-toastr";
import * as Icon from "react-feather";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback
} from "availity-reactstrap-validation";
//const avatar = myConstClass.SITE_URL+`img/jobimage.png`; 



const tablestyle = {
  'border-top': 'none',
  'border-right': '1px solid #dee2e6',
  'font-size': '16px',
  'width': '30%',
  'padding-right': '15px',
};
const tablestyletd = {
  'border-top': 'none',
  'font-size': '16px',

  'padding-left': '15px',
};




class Landing extends PureComponent {



  constructor(props) {
    super(props);
    //this.routeChange = this.routeChange.bind(this);
    // this.googleTranslateElementInit = this.googleTranslateElementInit.bind(this);
    //      this.search = this.search.bind(this);
    //this.toggledocTemplate1=this.toggledocTemplate1(this);
    this.state = { 'file': '' }
    this.state = {
      user_id: 0,
      formData: '',
      emp: '',
      posts: '',
      timeOut: 5000,
      showCloseButton: true,
      progressBar: true,
      position: "top-right",
      jobpostid: '',
      count: '',
      text1: '',
      text2: '',
      text3: '', text4: '', text5: '',
      text6: '',
      text7: '',
      text8: '',
      text9: '',
      text10: '',
      visiblepoints: false,
      visiblepoints1: false,
      submitted: false,
      organization_url_see: '',
      organization_logo_see: '',
      organization_title_see: '',
      job_title_see: '',
      job_desc_see: '',
      textShort_Description: '',
      textJobTitle: '',
      Longdescwithouthtml: '',
      isreadlong: '',
      account_id: '',
      account_name: '',
      submitted: true,
    }

    this.state = {
      jobpostidnew: '', selected: [],
      costs: [], imagess: [], titleorg: [], quantities: [], accountnm: [], job_title: [], job_desc: [],
    }
    //this.ochange = this.ochange.bind(this);
    this.change = this.change.bind(this);
    this.change1 = this.change1.bind(this);
    //this.oid=0;
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.routeChange = this.routeChange.bind(this);
    //for export
    this.csvColumns = [
      {
        displayName: "Title",
        id: "title"
      },
      {
        displayName: 'Code',
        id: "code"
      },
      {
        displayName: "Description",
        id: "description"
      },

    ];

    //submitted: false,
  }

  edit(e) {
    //this.props.history.push('/'+myConstClass.TENANT_PAGE_PATH+'jobpost/add/'+e);
    const id1 = cryptoRandomString({ length: 10, type: 'url-safe' });

    this.props.history.push({
      pathname: '/' + myConstClass.TENANT_PAGE_PATH + 'blog/add/' + id1,
      state: {
        _id: e,
      }
    }
    );
  }

  routeChange() {
    this.props.history.push('/');
  }






  toggledocTemplate = open_mod1 => {

    this.state.jobpostid = open_mod1;
    this.setState(state => ({
      [open_mod1]: !state[open_mod1]
    }));


  };

  toggledocTemplateApply = open_mod1 => {
    //  alert(open_mod1);
    //  alert(open_mod1);

    // var account_name=localStorage.getItem("account_name");

    // alert(answer_array);
    //var account_name=answer_array[2]; 
    fetch(myConstClass.CARREER_URL + this.state.account_name + '/index.php/career/getjobpostInfo/' + open_mod1)
      .then(response => response.json())
      .then(response => {

        this.setState({ textmoreJDDesc: response.Description })
        this.setState({ text2: response.JTitle })
        this.setState({ text3: response.JobType })
        this.setState({ text4: response.Position })
        this.setState({ text5: response.date_of_closing })
        this.setState({ url: response.http_link })
        this.setState({ Longdescwithouthtml: response.Long_Descriptionwithouthtml })
        this.setState({ JDdescwithouthtml: response.Descriptionmore })
        this.setState({ isreadlong: response.isreadlong })
        this.setState({ isreadjddesc: response.isreadjddesc })
        this.setState({ text1: response.Long_Description })
        this.setState({ textJobTitle: response.JTitle })
        this.setState({ textShort_Description: response.Short_Description })

      }).catch(err => console.log(err));






    this.setState({ checked: false });
    this.setState({ submitted: true });

    this.state.jobpostid = open_mod1;
    this.setState(state => ({
      [open_mod1]: !state[open_mod1]
    }));


  };

  toggleEdit = () => {

    this.setState({ 'visiblepoints': false });
  };



  toggleDes = () => {
    //this.state.jobpostidnew =  open_mod;

    this.setState({ 'visiblepoints1': true });



    this.setState({ 'visiblepoints1': true });






  };
  toggleDesMore = () => {

    $("#readmore").hide();
    $("#notreadmore").show();
  };
  toggleDesLess = () => {
    $("#notreadmore").hide();
    $("#readmore").show();
  }
  toggleDesMore1 = () => {

    $("#readmore1").hide();
    $("#notreadmore1").show();
  };
  toggleDesLess1 = () => {
    $("#notreadmore1").hide();
    $("#readmore1").show();
  }









  toggleEdit1 = open_mod => {
    //this.state.jobpostidnew =  open_mod;
    this.setState(state => ({
      [open_mod]: !state[open_mod]
    }));
    this.setState({ 'visiblepoints1': false });
  };







  toggledocTemplate1(e) {

    //alert(e);

    this.state.jobpostidnew = e;


    this.setState({ 'visiblepoints': true });

    // alert(answer_array);
    //var account_name=answer_array[2]; 
    //var account_name=localStorage.getItem("account_name");

    //this.state.user_id = answer_array['5'];
    fetch(myConstClass.CARREER_URL + this.state.account_name + '/index.php/career/getjobpostInfo/' + this.state.jobpostidnew)
      .then(response => response.json())
      .then(response => {

        this.setState({ textmoreJDDesc: response.Description })
        this.setState({ text2: response.JTitle })
        this.setState({ text3: response.JobType })
        this.setState({ text4: response.Position })
        this.setState({ text5: response.date_of_closing })
        this.setState({ url: response.http_link })
        this.setState({ Longdescwithouthtml: response.Long_Descriptionwithouthtml })
        this.setState({ JDdescwithouthtml: response.Descriptionmore })
        this.setState({ isreadlong: response.isreadlong })
        this.setState({ isreadjddesc: response.isreadjddesc })
        this.setState({ text1: response.Long_Description })
        this.setState({ textJobTitle: response.JTitle })
        this.setState({ textShort_Description: response.Short_Description })

        if (response.Gender == '1') {
          this.setState({ text7: 'Male' })

        } else if (response.Gender == '2') {
          this.setState({ text7: 'Female' })

        } else if (response.Gender == '3') {
          this.setState({ text7: 'No Preference' })
        }
        // else{
        //  this.setState({ text7:'Male'  })  
        // }



        if (response.Minimum_Experience == 1) {
          this.setState({ text6: 'Fresher' })

        } else if (response.Minimum_Experience == 2) {
          this.setState({ text6: '1 Year' })

        } else if (response.Minimum_Experience == 3) {
          this.setState({ text6: '2 Year' })
        }
        else if (response.Minimum_Experience == 4) {
          this.setState({ text6: '3 Year' })

        } else if (response.Minimum_Experience == 5) {
          this.setState({ text6: '4 Year' })
        }
        else if (response.Minimum_Experience == 6) {
          this.setState({ text6: '5 Year' })

        } else if (response.Minimum_Experience == 7) {
          this.setState({ text6: '6 Year' })
        }
        else if (response.Minimum_Experience == 8) {
          this.setState({ text6: '7 Year' })

        } else if (response.Minimum_Experience == 9) {
          this.setState({ text6: '8 Year' })
        }
        else if (response.Minimum_Experience == 10) {
          this.setState({ text6: '9 Year' })

        }
        else if (response.Minimum_Experience == 11) {
          this.setState({ text6: '10 Year' })

        }
        else if (response.Minimum_Experience == 12) {
          this.setState({ text6: '10+ Year' })

        }

        this.setState({ text9: response.Positions })
        this.setState({ text8: response.Short_Description })
        this.setState({ text10: response.posted_date })
        // this.setState({ Long_Description: response.Long_Description })
        //this.setState({JD_Title:[{ value:response.JD_Title, label:response.jdtitle }]});




      }).catch(err => console.log(err));























  };


  toggleDesMore1 = () => {

    $("#readmore1").hide();
    $("#notreadmore1").show();
  };
  toggleDesLess1 = () => {
    $("#notreadmore1").hide();
    $("#readmore1").show();
  }





  change = (selectedOrg) => {

    // alert(answer_array);
    //var account_name=answer_array[2]; 
    this.setState({ selectedOrg });
    // var new_account_id = localStorage.getItem('account_id'); 
    // this.cid = event.target.value;
    if (selectedOrg == null || selectedOrg == undefined) {
      this.cid = 0;
    } else {
      let x = Object.values(selectedOrg);
      // this.dep_id = event.target.value;
      this.cid = x[0];
    }
    if (this.pos_id == null || this.pos_id == '') {
      this.pos_id = 0;
    } else {
      this.pos_id = this.pos_id;
    }
    if (this.dep_id == null || this.dep_id == '') {
      this.dep_id = 0;
    } else {
      this.dep_id = this.dep_id;
    }
    if (this.oid == null || this.oid == undefined) {
      this.oid = 0;
    } else {
      this.oid = this.oid;
    }

    fetch(myConstClass.CARREER_URL + this.state.account_name + '/index.php/career/getjobpostInfoallcc/' + this.state.account_id + '/' + this.cid + '/' + this.oid)
      .then(response => response.json())
      .then(response => {
        let i = 1;
        let c = 1;
        response.forEach(element => {
          let id = element.id;
          element.action = <div><Icon.Edit3 style={{ margin: '0px 15px 0px 0px', cursor: 'Pointer' }} onClick={() => this.toggledocTemplate(id)} /></div>;
          // console.log(element);
          // element.id = i;
          i++;
          c++;















        });

        //this.setState({org: response})
        this.setState({ formData: response })
        this.setState({ count: c })
      }).catch(err => console.log(err))














  }




  change1 = (selectedOrg) => {

    // alert(answer_array);
    //var account_name=answer_array[2]; 
    this.setState({ organization_id1: selectedOrg });
    // var new_account_id = localStorage.getItem('account_id'); 
    // this.cid = event.target.value;
    if (selectedOrg == null || selectedOrg == undefined) {
      this.oid = 0;
    } else {
      let x = Object.values(selectedOrg);
      // let xii = indexOf(selectedOrg);
      //alert(xii);
      // this.dep_id = event.target.value;
      this.oid = x[0];
    }



    var indexoforg = this.state.selected.indexOf(this.oid);

    //alert(indexoforg);


    var organization_url = this.state.costs[indexoforg];
    var organization_logo = this.state.imagess[indexoforg];
    var organization_title = this.state.titleorg[indexoforg];
    //alert(this.state.costs);
    this.setState({ organization_url_see: organization_url });
    this.setState({ organization_logo_see: organization_logo });
    this.setState({ organization_title_see: organization_title });
    if (this.pos_id == null || this.pos_id == '') {
      this.pos_id = 0;
    } else {
      this.pos_id = this.pos_id;
    }
    if (this.dep_id == null || this.dep_id == '') {
      this.dep_id = 0;
    } else {
      this.dep_id = this.dep_id;
    }


    fetch(myConstClass.CARREER_URL + this.state.account_name + '/index.php/career/getjobpostInfoallcc/' + this.state.account_id + '/' + this.cid + '/' + this.oid)
      .then(response => response.json())
      .then(response => {
        let i = 1;
        let c = 1;
        response.forEach(element => {
          let id = element.id;
          element.action = <div><Icon.Edit3 style={{ margin: '0px 15px 0px 0px', cursor: 'Pointer' }} onClick={() => this.toggledocTemplate(id)} /></div>;
          // console.log(element);
          // element.id = i;
          i++;
          c++;
        });

        //this.setState({org: response})
        this.setState({ formData: response })
        this.setState({ count: c })
      }).catch(err => console.log(err))














  }














  componentDidMount() {


    const answer_array = this.props.location.pathname.split('/');

    // alert(answer_array);
    var company_name = answer_array[2];
    //	alert(company_name);

    var login_type = localStorage.getItem('login_type');
    // alert(login_type); 
    if (login_type == null || login_type != 'emp') {
      this.routeChange();
    }
    localStorage.setItem('joburl', '1');
    localStorage.setItem('joburlcomp', company_name);
    //  var new_account_id= localStorage.getItem('account_id'); 
    fetch(myConstClass.BASE_URL + 'organization/getJd_disclouser_Info')
      .then(response => response.json())
      .then(response => {

        //this.setState({category:[{ value:response.category, label:response.ttitle }]});
        if (response) {
          this.setState({ text: response.Description })
          this.setState({ user_id: '1' })

        }
        else {
          this.setState({ user_id: '0' })
        }

      }).catch(err => console.log(err))
    fetch(myConstClass.LOGIN_URL + 'career/get_all_organizationsbycompany/' + company_name)
      .then(response => response.json())
      .then(response => {



        response.forEach(element => {
          //this.setState({totalccspan: response.totalAmount});
          // alert(element.id);

          /*  element.action = <div><Icon.Edit3 style={{margin:'0px 15px 0px 0px',cursor:'Pointer'}} onClick={() =>this.edit(id)} /><Icon.Trash2 style={{cursor:'Pointer'}} onClick={() =>this.remove(id)}/></div>;
             // console.log(element);
             element.id = i;
             i++;*/



          this.setState(() => ({
            // selected: this.state.selected ? [...this.state.selected, row.id] : []
            quantities: [...this.state.quantities, element.tenant_id]
          }));

          this.setState(() => ({
            // selected: this.state.selected ? [...this.state.selected, row.id] : []
            selected: [...this.state.selected, element.id]
          }));


          this.setState(() => ({
            // selected: this.state.selected ? [...this.state.selected, row.id] : []
            costs: [...this.state.costs, element.http_link]
          }));


          this.setState(() => ({
            // selected: this.state.selected ? [...this.state.selected, row.id] : []
            imagess: [...this.state.imagess, element.org_logo_url]
          }));



          this.setState(() => ({
            // selected: this.state.selected ? [...this.state.selected, row.id] : []
            titleorg: [...this.state.titleorg, element.title]
          }));


          this.setState(() => ({
            // selected: this.state.selected ? [...this.state.selected, row.id] : []
            accountnm: [...this.state.accountnm, element.account_name]
          }));





        });
        var accountname = this.state.accountnm[0];
        var accountnid = this.state.quantities[0];
        //alert(localStorage.getItem('account_id_new'));
        //this.setState({account_id:localStorage.getItem('account_id_new')});


        var organization_id1 = this.state.selected[0];
        //alert(accountname);
        var organization_url = this.state.costs[0];
        var organization_logo = this.state.imagess[0];
        var organization_title = this.state.titleorg[0];
        //alert(this.state.costs);
        this.setState({ account_name: accountname });
        this.setState({ organization_url_see: organization_url });
        this.setState({ organization_logo_see: organization_logo });
        this.setState({ organization_title_see: organization_title });
        this.setState({ organization_id11: organization_id1 });
        this.setState({ account_id: accountnid });















        if (this.cid == null || this.cid == undefined) {
          this.cid = 0;
        } else {
          this.cid = this.cid;
        }

        if (this.oid == null || this.oid == undefined) {
          this.oid = organization_id1;
        } else {
          this.oid = this.oid;
        }

        // document.body.appendChild(script);  

        //    document.querySelector('.react-bootstrap-table').style.minHeight  = "243px";


        fetch(myConstClass.CARREER_URL + accountname + '/index.php/career/getjobpostInfoallcc/' + accountnid + '/' + this.cid + '/' + this.oid)
          .then(response => response.json())
          .then(response => {
            let i = 1;
            let c = 1;
            response.forEach(element => {
              let id = element.id;
              element.action = <div><Icon.Edit3 style={{ margin: '0px 15px 0px 0px', cursor: 'Pointer' }} onClick={() => this.toggledocTemplate(id)} /></div>;
              // console.log(element);
              // element.id = i;
              i++;
              c++;




              this.setState(() => ({
                // selected: this.state.selected ? [...this.state.selected, row.id] : []
                job_title: [...this.state.job_title, element.JobTitle]
              }));


              this.setState(() => ({
                // selected: this.state.selected ? [...this.state.selected, row.id] : []job_titlejob_desc
                job_desc: [...this.state.job_desc, element.Short_Description]
              }));































            });

            //  this.setState({org: response})
            this.setState({ formData: response })
            this.setState({ count: c })
          }).catch(err => console.log(err))
        //var admin_id= localStorage.getItem('uname'); 
        fetch(myConstClass.CARREER_URL + accountname + '/index.php/organization/get_all_jd/' + accountnid)
          .then(response => response.json())
          .then(response => { this.setState({ org: response }); }).catch(err => console.log(err));




































        //this.setState({organization_id1:[{ value: organization_id1, label: organization_title }]});   
        this.setState({ emp: response })
      }).catch(err => console.log(err))




















    //alert(this.oid);

















  }



  onDocChangeHandler(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        // imagePreviewUrl: reader.result
      });
    }
    reader.readAsDataURL(file)
  }




  handleValidSubmitDocument = () => {

    //alert(this.state.file);
    if (this.state.file != undefined) {

      // alert(answer_array);
      //var account_name=answer_array[2]; 
      this.setState({ submitted: true }, () => {
        // setTimeout(() => this.setState({ submitted: false }), 5000);
        const data = new FormData()
        data.append('file', this.state.file);
        data.append('jobpostid', this.state.jobpostid);
        data.append('formdata', JSON.stringify($('#doform').serializeArray()));
        fetch(myConstClass.CARREER_URL + this.state.account_name + `/index.php/career/add_applydocumment/` + this.state.account_id, {
          method: 'POST',
          body: data,
          // contentType: "application/json; charset=utf-8",
        }).then(response => response.json()).then((response) => {
          this.setState({ submitted: false });
          if (response == true) {




            //this.state.jobpostid =  open_mod1;
            this.setState(state => ({
              [this.state.jobpostid]: !state[this.state.jobpostid]
            }));


            toastr.success(
              'Success',
              "Resume Uploaded Successfully..!",
              this.options
            );

            // this.props.history.push('/'+myConstClass.TENANT_PAGE_PATH+'complaints/listing');
          } else {
            toastr.error(
              'Error',
              "Resume Not Uploaded Successfully..!",
              this.options
            )
          }
        })
          .catch();
      });
    }
    else {

      toastr.error(
        'Error',
        "Please Upload Resume ..!",
        this.options
      )
    }
  }

  view() {

    this.setState({ 'visiblediscloser': true });
  }

  toggleDesMore1 = () => {

    $("#readmore1").hide();
    $("#notreadmore1").show();
  };
  toggleDesLess1 = () => {
    $("#notreadmore1").hide();
    $("#readmore1").show();
  }
  viewclose() {

    this.setState({ 'visiblediscloser': false });
  }
  handleCheckChange() {
    //  alert(this.state.checked);
    this.setState({
      submitted: this.state.checked
    })
    this.setState({
      checked: !this.state.checked
    })
  }
  render() {

    // alert(answer_array);
    //	var account_name=answer_array[2]; 
    const { org, formData, count, text1, textmoreJDDesc,
      text2, text3, text4, text5, text6, text7, text8, text10, text,
      submitted, url, emp, organization_url_see, organization_logo_see, organization_title_see
      , textShort_Description, textJobTitle, isreadlong, user_id, isreadjddesc
    } = this.state;

    let orgOp = org ? org.map((cell) => {
      return { value: cell.id, label: cell.title };
    }) : '';

    let balance_div_url;
    let organization_div_url, organization_title_url, organization_div_url_back;
    let balance_disclouser;
    balance_disclouser = user_id !== '0' ? <a target="_blank" class="mr-1 mb-1 btn btn-outline-secondary" style={{ cursor: 'Pointer', 'color': 'white', 'borderColor': 'white' }} onClick={() => this.view()} >Show Disclosure</a> : '';

    balance_div_url = url ? <a target="_blank" class="mr-1 mb-1 btn btn-outline-secondary" href={url}  >See on JOBlinks <Icon.ArrowUpRight style={{ cursor: 'Pointer' }} /></a> : '';
    // organization_div_url=organization_url_see?<a target="_blank" href={organization_url_see}  >See on CamJOBS for this organization</a>:'';
    organization_title_url = organization_title_see ? <span style={{ 'font-size': '35px', 'marginLeft': '11px' }}><label>{organization_title_see}</label></span> : '';
    organization_div_url = organization_url_see ? <a style={{ cursor: 'Pointer', 'color': 'white', 'borderColor': 'white' }} class="mr-1 mb-1 btn btn-outline-secondary" href={organization_url_see} target="_blank">See on JOBlinks <Icon.ArrowUpRight style={{ cursor: 'Pointer' }} /></a> : '';
    organization_div_url_back = <a style={{ cursor: 'Pointer', 'color': 'white', 'borderColor': 'white' }} class="mr-1 mb-1 btn btn-outline-secondary" href={'/' + myConstClass.TENANT_PAGE_PATH + "attendance/checkinout"}  >Back To Dashboard</a>;
    //organization_div_url_back=<Link to={'/'+myConstClass.TENANT_PAGE_PATH+"attendance/checkinout"} style={{margin:"0rem 1.50rem 1.50rem 0rem",border: "#6f42c1 1px solid",color: "#6f42c1",padding: "0.10rem 0.90rem 0.30rem 0.90rem",textDecoration: 'none',borderRadius:'0.2rem'}}>Back To Dashboard</Link>;
    //organization_div_url=organization_url_see?<Link to={organization_url_see} style={{margin:"0rem 1.50rem 1.50rem 0rem",border: "#6f42c1 1px solid",color: "#6f42c1",padding: "0.10rem 0.90rem 0.30rem 0.90rem",textDecoration: 'none',borderRadius:'0.2rem'}} target="_blank">Cancel</Link>:'';

    let file_link, image_logo;
    file_link = myConstClass.SITE_URL + organization_logo_see;
    // image_logo=organization_logo_see?<img src={file_link}  style={{width: '134px',height:'77px',marginTop:'-23px'}}/>:'';
    image_logo = organization_logo_see ? <img src={file_link} style={{ width: '230px', height: '60px', marginTop: '-13px' }} /> : '';









    let balance_div;
    let file_linknew, readmore;
    balance_div = formData ? formData.map((cell) => {

      if (cell.image) {
        file_linknew = myConstClass.CARREER_URL + this.state.account_name + `/uploads/job_post_image/` + cell.id + '/' + cell.image;
      }
      else {
        file_linknew = myConstClass.SITE_URL + `img/jobimage.png`;
      }







      readmore = cell.isread == '1' ? <CardLink href="#" onClick={() => { }}>Read More</CardLink> : '';
      return <Row>
        <Col lg={12}>
          <Card >













            <CardHeader>
              <CardTitle tag="h5" className="mb-0">

                <Row>
                  <Col lg={2}>
                    <CardImg style={{ 'height': '98px' }} src={file_linknew} />










                  </Col>

                  <Col lg={8} >

                    <label style={{ 'font-weight-bold': '100px' }}>{cell.JobTitle}</label>






                    <br />
                    {cell.Short_Description} {readmore}



















                  </Col>
                  <Col lg={2} >
                    <button class="mr-1 mb-1 btn btn-outline-secondary" onClick={() =>





                      this.toggledocTemplate1(cell.id)


                    }>View More</button>

                    <Button class="secondary" style={{ 'marginLeft': '10px', 'marginTop': '-5px' }} onClick={() => this.toggledocTemplateApply(cell.id)}>Apply
                    </Button>

                  </Col>
                </Row>


              </CardTitle>
            </CardHeader>

          </Card>
        </Col>
      </Row>;















    }) : '';



    //for export csv

    return (


      <React.Fragment>
        <Navbar dark expand="xs" className="absolute-top w-100 py-2" style={{ 'backgroundColor': '#153d77', 'height': '90px' }}>
          <Container>
            <NavbarBrand className="font-weight-bold" >
              {image_logo}{organization_title_url}
            </NavbarBrand>
            <div>
              {balance_disclouser}
              {organization_div_url}{organization_div_url_back}  </div>

          </Container>
        </Navbar>
        {/* <Intro /> */}

        {/* <Navigation />*/}

        <section className="pt-7 pb-5" style={{ marginTop: '26px' }}>
          <Container>
            <div >
              <h2 className="mb-4 text-center">Available Jobs({count - 1})</h2>
              <p className="text-muted mb-4 text-center">
                <label style={{ 'color': 'black' }}>  Following are available Jobs</label>


              </p>



              <AvForm id="pform" >

                <Row className="c_row">


                  { /* <div class="col-md-6">
                 
                  <AvGroup>
                 <label>Select Organization</label>
                      <Select
                           value={organization_id1}
                           name="organization_id1"
                           onChange={this.change1}
                           options={orgOp1}
                          isClearable
                          placeholder='Select Organization'
                      />
	
	  </AvGroup>
  </div>*/}






                  <div class="col-md-6">

                    <AvGroup>

                      <label>Select Job Type</label>
                      <Select
                        value={this.state.organization_id}
                        name="organization_id"
                        onChange={this.change}
                        options={orgOp}
                        //isClearable
                        placeholder='Select Job Type'
                      />

                    </AvGroup>

                  </div>
                </Row>
              </AvForm>
            </div>
            <br />

            {balance_div}
            <Modal
              size="lg" style={{ maxWidth: '900px', width: '100%' }}
              isOpen={this.state[this.state.jobpostid]}
            //   toggle={() => this.toggleEdit(this.state.jobpostid)}
            >
              <AvForm id="doform" onValidSubmit={this.handleValidSubmitDocument}>
                <ModalHeader style={{ 'backgroundColor': '#D9DDDE' }} >
                  <label >Applying For Job Post: {textJobTitle}</label><br />

                  {textShort_Description}

                </ModalHeader>
                <ModalBody >
                  <CardBody>


                    <Row>
                      <Col lg={6}>
                        <AvGroup>
                          <Label for="example">Name</Label>
                          <AvInput name="title" id="example" required />
                          <AvFeedback>Enter Name!</AvFeedback>
                        </AvGroup>
                      </Col>

                      <Col lg={6}>
                        <AvGroup>
                          <Label for="example">Mobile No</Label>
                          <AvInput name="mobile_no" id="example" type="number" required />
                          <AvFeedback>Enter Mobile No!</AvFeedback>
                        </AvGroup>
                      </Col>

                      <Col lg={6}>
                        <AvGroup>
                          <Label for="example">Email</Label>
                          <AvInput name="email" type="email" id="example" required />
                          <AvFeedback>Enter Email!</AvFeedback>
                        </AvGroup>
                      </Col>




                      <Col lg={6}>
                        <AvGroup>
                          <Label for="example">Upload Resume</Label>   <br />
                          <input type="file" id='file1' name="file" onChange={(e) => this.onDocChangeHandler(e)} required />
                          <AvFeedback>Upload Resume!</AvFeedback>
                        </AvGroup>
                        <small>('png','jpg','jpeg','pdf','docs','JPG','txt')</small>
                      </Col>


                      <Col lg={6}>
                        <AvGroup>
                          <Label for="example">Linkedin Profile </Label>
                          <AvInput name="profile_url" id="example" type="url" />

                        </AvGroup>
                      </Col>


                      <Col lg={6}>
                        <AvGroup>
                          <Label for="example">Self Introductory Video  <span style={{ color: 'red', fontSize: '13px' }}>embeded video youtube url</span></Label>
                          <AvInput name="self_url" id="example" type="text" />
                          <AvFeedback>Enter URL !</AvFeedback>
                        </AvGroup>
                      </Col>

                      <Col lg={6}>
                        <CustomInput
                          type="checkbox"
                          name="toplist"
                          id="exampleCustomCheckbox"
                          label="See and Approve Disclosure"

                          className="mb-2"
                          checked={this.state.checked}
                          onChange={this.handleCheckChange}
                        />
                      </Col>


                    </Row>


                  </CardBody>
                </ModalBody>
                <ModalFooter >

                  <div style={{ 'textAlign': 'right' }}>
                    <button style={{ 'marginTop': '37px' }} class="mr-1 mb-1 btn btn-outline-secondary" onClick={() => this.toggledocTemplate(this.state.jobpostid)}>
                      Close
                    </button>{" "}
                    <Button style={{ 'marginTop': '32px' }} disabled={submitted}>Apply</Button>
                  </div>












                </ModalFooter>

              </AvForm><br />
            </Modal>




            <Modal
              size="lg" style={{ maxWidth: '900px', width: '100%' }}
              isOpen={this.state.visiblepoints}
            //toggle={() => this.toggleEdit(this.state.jobpostidnew)}
            >
              <ModalHeader style={{ 'backgroundColor': '#D9DDDE' }} >
                <Row><div > <label style={{ 'width': '715px' }} >{text2}</label><label> {balance_div_url} </label></div>

                  <div style={{ "textAlign": "right" }}></div></Row>


                {text8}

                <div className="card-actions float-right">
                  <span className="cursor-pointer mr-1">

                  </span>{" "}












                </div>
              </ModalHeader>
              <ModalBody className="m-9">



                <Table size="sm" className="my-2">
                  <tbody>
                    <tr>
                      <th className="text-right" style={tablestyle}>Job Type</th>
                      <td className="text-left" style={tablestyletd}>{text3}</td>
                    </tr>
                    <tr>
                      <th className="text-right" style={tablestyle}>Position</th>
                      <td className="text-left" style={tablestyletd}>{text4}</td>
                    </tr>
                    <tr>
                      <th className="text-right" style={tablestyle}>Date Of Closing</th>
                      <td className="text-left" style={tablestyletd}>{text5}</td>
                    </tr>
                    <tr>
                      <th className="text-right" style={tablestyle}>Date Of Posted</th>
                      <td className="text-left" style={tablestyletd}>{text10}</td>
                    </tr >
                    <tr>
                      <th className="text-right" style={tablestyle}>Minimum_Experience</th>
                      <td className="text-left" style={tablestyletd}>{text6}</td>
                    </tr>
                    <tr>
                      <th className="text-right" style={tablestyle}>Gender</th>
                      <td className="text-left" style={tablestyletd}>{text7}</td>
                    </tr>
                    <tr>
                      <th className="text-right" style={tablestyle}>Long Description</th>
                      <td className="text-left" style={tablestyletd}>

                        <div id="readmore" style={{ 'display': 'block' }}>
                          <ReactQuill value={this.state.Longdescwithouthtml || ''} readOnly={true}
                            theme={"bubble"}
                          />{isreadlong == '1' ? (<CardLink href="#" onClick={() => this.toggleDesMore(this.state.jobpostidnew)}>Read More</CardLink>) : ''}
                        </div>
                        <div id="notreadmore" style={{ 'display': 'none' }}>
                          <ReactQuill value={text1 || ''} readOnly={true}
                            theme={"bubble"}
                          /><CardLink href="#" onClick={() => this.toggleDesLess(this.state.jobpostidnew)}>Show Less</CardLink>

                        </div>

                      </td>
                    </tr>

                    <tr>
                      <th className="text-right" style={tablestyle}>Job Description</th>
                      <td className="text-left" style={tablestyletd}>

                        <div id="readmore1" style={{ 'display': 'block' }}>
                          <ReactQuill value={this.state.JDdescwithouthtml || ''} readOnly={true}
                            theme={"bubble"}
                          />{isreadjddesc == '1' ? (<CardLink href="#" onClick={() => this.toggleDesMore1(this.state.jobpostidnew)}>Read More</CardLink>) : ''}
                        </div>
                        <div id="notreadmore1" style={{ 'display': 'none' }}>
                          <ReactQuill value={textmoreJDDesc || ''} readOnly={true}
                            theme={"bubble"}
                          /><CardLink href="#" onClick={() => this.toggleDesLess1(this.state.jobpostidnew)}>Show Less</CardLink>

                        </div>

                      </td>
                    </tr>
                    <tr></tr>
                  </tbody>
                </Table>
              </ModalBody>
              <ModalFooter >

                <Button style={{ 'marginTop': '37px' }} class="mr-1 mb-1 btn btn-outline-secondary" onClick={() => this.toggleEdit(this.state.jobpostidnew)}>

                  Close
                </Button>{" "}

              </ModalFooter>
            </Modal>








            <Modal
              size="lg" style={{ maxWidth: '900px', width: '100%' }}
              isOpen={this.state.visiblepoints1}
            //toggle={() => this.toggleEdit(this.state.jobpostidnew)}
            >
              <ModalHeader >
                <h4>View Long Description</h4>
              </ModalHeader>
              <ModalBody className="text-center m-9">
                <Card>
                  <CardHeader>

                    <CardTitle tag="h5" className="mb-0">
                      Long Description of {text2}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>

                    <Row>
                      <Col lg={12}>


                        <ReactQuill value={this.state.text1} readOnly={true}
                          theme={"bubble"}
                        />
                      </Col>
                    </Row>

                  </CardBody>
                </Card>
              </ModalBody>
              <ModalFooter >
                <Button style={{ 'marginTop': '37px' }} class="mr-1 mb-1 btn btn-outline-secondary" onClick={() => this.toggleEdit1(this.state.jobpostidnew)}>
                  Close
                </Button>{" "}

              </ModalFooter>
            </Modal>


            <Modal size="lg" style={{ maxWidth: '700px', width: '100%' }}
              isOpen={this.state.visiblediscloser}
            >
              <ModalHeader  >

              </ModalHeader>
              <ModalBody className="text-center m-9">


                <CardHeader>
                  {/* {T("View")} {T("Comment")} */}

                </CardHeader>
                <CardBody>

                  <ReactQuill value={this.state.text} theme={"bubble"} />

                </CardBody>
              </ModalBody>
              <br />
              <ModalFooter>
                <Button color="secondary" onClick={() => this.viewclose()}>
                  Close
                </Button>{" "}
              </ModalFooter>
            </Modal>






          </Container>
        </section>


      </React.Fragment>


    );
  }
}
export default connect()(Landing);