
module.exports = {
  "Yes": "បាទ ឫ ចាស",
  "Male": "ប្រុស",
  "Female": "ស្រី",
  "Other": "ផ្សេង",
  "Non": "មិនមែន",
  "Spouse": "ប្តី ប្រពន្ធ",
  "Child": "កូន",
  "husband": "ប្តី",
  "brother": "បងប្អូនប្រុស",
  "sister": "បងប្អូនស្រី",
  "grandfather": "លោកតា",
  "grandmother": "លោកយាយ",
  "grandson": "ចៅប្រុស",
  "granddaughter": "ចៅស្រី",
  "uncle": "ពូ",
  "aunt": "មីង",
  "nephew": "ក្មួយប្រុស",
  "niece": "ក្មួយស្រី",
  "cousin": "បងប្អូនជីដូនមួយ",
  "friend": "មិត្ត",
  "Monthly": "ប្រចាំខែ",
  "Yearly": "ប្រចាំឆ្នាំ",
  "Security": "សន្តិសុខ",
  "and": "និង",
  "Sub": "អនុ",
  "Admin": "រដ្ឋាបាល",
  "Comment": "មតិ់",
  "Description": "ពណ៌នា",
  "See": "ឃើញ",
  "Make": "បង្កើត",
  "ON": "នៅលើ",
  "Amount": "ចំនួន",
  "Added": "បន្ថែម",
  "Successfully": "ដោយជោគជ័យ",
  "Updated": "បានធ្វើបច្ចុប្បន្នភាព",
  "Your": "របស់អ្នក",
  "form": "ទម្រង់",
  "is": "គឺ",
  "submitted": "បានបញ្ជូន",
  "Manage": "គ្រប់គ្រង",
  "marked": "សម្គាល់",
  "with": "ជាមួយ",
  "are": "គឺ",
  "required": "តម្រូវការ",
  // "Monthly":"ប្រចាំខែ",
  "Hourly": "ប្រចាំម៉ោង",
  "Weekly": "ប្រចាំសប្តាហ៍",
  "OK": "យល់ព្រម",
  "Complaint": "ត្អូញត្អែរឬពាក្យបណ្តឹង",
  "From": "ពី",
  "Subject": "ប្រធានបទ",
  "Category": "ប្រភេទ",
  "Number of Days Outstanding": "ចំនួនថ្ងៃដែលលើស",
  "Open": "បើក",
  "Are you sure?": "តើអ្នកប្រាកដទេ",
  "that you want to delete this record?": "តើអ្នកចង់លុបកំណត់ត្រានេះ?",
  "Deleted": "លុប",

  "View": "ទិដ្ឋភាព",
  "Against": "ប្រឆាំង",
  "Uploaded": "ផ្ទុកឡើង",
  "Taken": "បានយក",
  "Attendance": "វត្តមាន",
  "In-Time": "ម៉ោងចូល",
  "Out-Time": "ម៉ោងចេញ",
  "Total": "សរុប",
  "Work": "ធ្វើការ",
  "Time": "ម៉ោង",
  "Break": "សម្រាក",
  "Are you sure that you want to edit this record which done by hr admin?": "តើអ្នកប្រាកដថាអ្នកចង់កែសម្រួលកំណត់ត្រានេះដែលធ្វើឡើងដោយអ្នកគ្រប់គ្រងទេ?",
  "Are you sure that you want to edit this record which done by employee login?": "តើ​អ្នក​ប្រាកដ​ថា​អ្នក​ចង់​កែសម្រួល​កំណត់​ត្រា​នេះ​ដែល​ធ្វើ​ឡើង​ដោយ​ការ​ចូល​របស់​បុគ្គលិកទេ?",
  "Deleted Successfully": "លុប​ដោយ​ជោគជ័យ",
  "Archived": "ទុកជាឯកសារ",
  "Check In Radius Check": "កំណត់ទីតាំងចូល",
  "Check Out Radius Check": "កំណត់ទីតាំងចេញ",
  "Done By HR ADMIN": "ធ្វើដោយអ្នកគ្រប់គ្រងធនធានមនុស្ស",
  "Write reason of delete:": "សរសេរមូលហេតុនៃការលុប:",
  //"Are you sure?":"តើអ្នកប្រាកដទេ?",
  "Are you sure that you want to delete this record?": "តើ​អ្នក​ប្រាកដ​ថា​អ្នក​ចង់​លុប​កំណត់​ត្រា​នេះ?",
  "ADD": "បន្ថែម",
  "Hrs": "ម៉ោង",
  "Min": "នាទី",
  "Enter Rest Time as Hrs:Min": "បញ្ចូលម៉ោងសម្រាកជាម៉ោង: នាទី",
  "Enter Early Leaving as Hrs:Min": "ចូលការចាកចេញមុនម៉ោងជាម៉ោង:នាទី",
  "Please enter In time": "សូមបញ្ចូលម៉ោងចូល",
  "Please enter Out time": "សូមបញ្ចូលម៉ោងចេញ",
  "Can not Add Attendance": "មិន​អាច​បន្ថែម​វត្តមាន",
  "YYYY-MM-DD": "ឆ្នាំ-ខែ-ថ្ងៃ",
  "All fields marked with * are required": "ឯកសារទាំងអស់ដែលបានសម្គាល់ដោយផ្កាយ *គឺតម្រូវ",
  "Positions": "តួនាទី",
  "Departments": "ផ្នែក",
  "Actual": "ជាក់ស្តែង",
  "Reason": "ហេតុផង",
  "Can not Add Privilege": "មិនអាចបន្ថែមសិទ្ធិបានទេ",
  "Can not Edit Privilege": "មិន​អាច​កែសម្រួល​សិទ្ធ",
  "Can not Delete Privilege": "មិនអាចលុបសិទ្ធ",
  "Pending": "កំពុងរង់ចាំ",
  "Approved": "បានអនុម័ត",
  "Rejected": "បានបដិសេធ",
  "Please": "សូម",
  //"Add":"បន្ថែម",
  "Check Your Leave Bank": "ពិនិត្យសមតុល្យវត្តមានរបស់អ្នក",
  "From Date should not be greater than To Date": "ចាប់ពីកាលបរិច្ឆេទ មិនគួរធំជាង ដល់កាលបរិច្ឆេទទេ",
  //"Check Your Leave Bank":"ពិនិត្យសមតុល្យវត្តមានរបស់អ្នក",
  "Please add leave templete for this employee ": "សូម​បន្ថែម​គំរូ​ការ​ឈប់​សម្រាក​សម្រាប់​បុគ្គលិក​នេះ",
  "Leave present at this date": "វត្តមាន​នៅ​ក្នុងកាលបរិច្ឆេទ​នេះ",
  "can": "អាច",
  "not": "ទេ",
  "Start": "ចាប់ផ្តើម",
  "End": "បញ្ចប់",
  "Duration": "កំឡុងពេល",
  "Starts in first half": "ចាប់ផ្តើមនៅក្នុងពាក់កណ្តាលទីមួយ",
  "Starts in second half": "ចាប់ផ្តើមនៅពាក់កណ្តាលទីពីរ",
  "Full Day Leave": "សម្រាកពេញ១ថ្ងៃ",
  "Post Edit Remark": "ប្រកាសកែសម្រួលកំណត់ចំណាំ",
  "Attachment": "ឯកសារ​ភ្ជាប់",
  "Staff": "បុគ្គលិក",
  "Staffs": "បុគ្គលិក",
  "Exists": "មានស្រាប់",
  "Not": "មិន",
  "Found": "រកឃើញ",
  "Any": "ណាមួយ",
  "App": "កម្មវិធី",
  "URL": "URL",
  "Go to App Url": "ចូលទៅកាន់កម្មវិធី Url",
  "Created At": "បង្កើតឡើងនៅ",
  "Announcement": "សេចក្តីជូនដំណឹង",
  "Announcements": "សេចក្តីជូនដំណឹង",
  "Success": "ជោគជ័យ",
  "Available": "អាចប្រើបាន",
  "Model": "ម៉ូឌែល",
  "Asset": "ទ្រព្យសកម្ម",
  "Assigned": "ចាត់តាំង",
  "Issue On": "ថ្ងៃដែលបង្កើត",
  "Sr": "លេខ​សម្គាល់",
  "Please Start the camera": "សូម​ចាប់​ផ្តើម​កាមេរ៉ា",
  "Please add the image": "សូមបញ្ចូលរូបភាព",
  "Your last Checked out": "ចុងក្រោយរបស់អ្នកបានចេញក្រៅ",

  "Bulk Import Failed": "ការនាំចូលជាក្រុមបានបរាជ័យ",
  "in": "ក្នុង",
  "Sample": "គំរូ",
  "CSV": "CSV",
  "File": "ឯកសារ",
  "Upload": "ផ្ទុកឡើង",
  "You must set location allow": "អ្នកត្រូវតែកំណត់ទីតាំងអនុញ្ញាត",
  "Check Inout": "ពិនិត្យការបញ្ចូល",
  "Submit": "បញ្ជូន",
  "Retry": "ព្យាយាមម្តងទៀត",
  "You already Checked In": "អ្នក​បាន​ចូល​រួច​ហើយ",
  "Check In": "ម៉ោងចូល",
  "Check Out": "ម៉ោងចេញ",
  "Latitude": "រយៈទទឹង",
  "Longitude": "រយៈបណ្តោយ",
  "List": "បញ្",
  "Employees": "បុគ្គលិក",
  "Dashboard": "ផ្ទាំងគ្រប់គ្រង",
  "Select": "ជ្រើសរើស",
  "Organization": "ស្ថាប័ន",
  "Status": "ស្ថានភាព",
  "Department": "នាយកដ្ឋាន(ផ្នែក)",
  "Position": "តួនាទី",
  "Export": "នាំចេញឬទាញចេញ",
  "Import": "នាំចូលឬទាញចូល",
  "Update": "ធ្វើបច្ចុប្បន្នភាព",
  "Qrcode": "ខ្យូអ៊រកូដ",
  "Search": "ស្វែងរក",
  "No": "ទេ",
  "New": "ថ្មី",
  "Complaints": "ត្អូញត្អែរ",
  "Information": "ពត៌មាន",
  "Employee": "បុគ្គលិក",
  "Email": "អ៊ីម៉ែល",
  "Mobile": "ទូរស័ព្ទ",
  "Action": "សកម្មភា",
  "Add": "បន្ថែម",
  "Edit": "កែប្រែ",
  "Basic": "មូលដ្ឋាន",
  "Info": "ពត៌មាន",
  "Leaves": "ច្បាប់",
  "Points": "ពិន្ទុ",
  "Documents": "ឯកសារ",
  "Assets": "ទ្រព្យសកម្ម",
  "Salary": "ប្រាក់បៀវត្ស",
  "Note": "សម្គាល់",
  "Payslips": "ចុងសន្លឹកប្រាក់ខែ",
  "Emergency": "បន្ទាប់",
  "Contacts": "ទំនាក់ទំនង",
  "First": "មុន",
  "Name": "ឈ្មោះ",
  "ID": "លេខសម្គាល់",
  "Last": "ចុងក្រោយ",
  "Middle": "កណ្តាល",
  "Address": "អាស័យដ្ឋាន",
  "Gender": "ភេទ",
  "Date": "ថ្ងៃ",
  "Of": "នៃ",
  "Birth": "កើត",
  "Timezone": "ល្វែងម៉ោង",
  "Location": "ទីតាំង",
  "Long": "វែង",
  "Lat": "ខ្លី_KH",
  "Restrict": "ដាក់កម្រិត",
  "CheckIn": "ម៉ោងចូល",
  "Radius": "កាំ",
  "CheckOut": "ម៉ោងចេញ",
  "Nationality": "សញ្ជាតិ",
  "Resident": "អ្នកស្រុក",
  "Shift": "វេន",
  "Group": "ក្រុម",
  "JD": "ពិពណ៌នាការងារ",
  "Title": "ប្រធានបទ",
  "Access": "ការចូលប្រើ",
  "UserType": "ប្រភេទអ្នកប្រើប្រាស់",
  "QR": "ខ្យូអ៊រ",
  "Download": "ទាញយក",
  "Cancel": "លុប",
  "Enter": "បញ្ចូល",
  "Password": "លេខសម្ងាត់",
  "In-Active": "មិនដំណើរការ",
  "Dependent": "ឯករាជ្យ",
  "Relation": "ទំនាក់ទំនង",
  "Present": "បច្ចុប្បន្ន",
  "Age": "អាយុ",
  "Save": "រក្សា",
  "changes": "ផ្លាស់ប្តូរ",
  "Leave": "ច្បាប់",
  "Template": "គំរូ",
  "Current": "បច្ចុប្បន្ន",
  "Balance": "សមតុល្យ",
  "Allowed": "អនុញ្ញាត",
  "Negative": "អវិជ្ជមាន",
  "Transaction": "ប្រត្តិបត្តិការ",
  "History": "ប្រវត្តិសាស្រ្ត",
  "Mannual": "សៀវភៅណែនាំ",
  "Credit": "ឥណទាន",
  "Debit": "ឥណពន្ធ",
  "Close": "បិទ",
  "Frequency": "ហ្រ្វកង់",
  "Quantity": "បរិមាណ",
  "Paycode": "លេខកូដបង់ប្រាក់",
  "Type": "ប្រភេទ",
  "Opening": "បើក",
  "All": "ទាំងអស់",
  "Document": "ឯកសារ",
  "Days": "ថ្ងៃ",
  "to": "ទៅ",
  "Already": "រួចហើយ",
  "Expired": "ផុតកំណត់",
  "Expiry": "ផុតកំណត់",
  "Remark": "ចំណាំ",
  "Declaration": "សេចក្តីប្រកាស",
  "Issue": "បញ្ហា",
  "Return": "ត្រឡប់មកវិញ",
  "Allowance": "ប្រាក់ឧបត្ថម្ភ",
  "Code": "កូដ",
  "taxable": "ជាប់ពន្ធ",
  "tax": "ពន្ធ",
  "Telephone": "ទូរស័ព្ទ",
  "Distance": "ចម្ងាយ",
  "Check out details are Not Available": "ពត៌មានលម្អិតអំពីម៉ោងចូលមិនមានទេ",
  "Details": "លម្អិត",
  "Get": "ទទួល",
  "Image": "រូបភាព",
  "This entry is generated by Admin": "ធាតុនេះត្រូវបានបង្កើតឡើងដោយអ្នកគ្រប់គ្រង",
  "for": "សម្រាប់",
  "CheckInOut": "ម៉ោងចេញចូល",
  "enter": "បញ្ចូល",
  "Email ID": "លេខសំគាល់​សារ​អេ​ឡិច​ត្រូ​និ​ច",
  "password": "លេខសំងាត់",
  "Incorrect": "មិន​ត្រឹមត្រូវ",
  "Credentials": "លិខិតសម្គាល់",
  "Login": "ចូល",
  "Unsuccessful": "មិនជោគជ័យ",
  "Forgot": "ភ្លេច",
  "Sign Up Now": "ចុះឈ្មោះឥឡូវនេះ",
  "Sign in": "ចូល",
  "Banner": "ផ្ទាំងផ្សព្វផ្សាយ",
  "Size": "ទំហំ",
  "Sequence": "លំដាប់",
  "Publish": "បោះពុម្ពផ្សាយ",
  "Blog": "ពត៌មានចែករំលែក",
  "Blogs": "ពត៌មានចែករំលែក",
  "Draft": "សេចក្តីព្រាង",
  "Unpublished": "មិនបានផ្សព្វផ្សាយ",
  "Published": "ផ្សព្វផ្សាយ",
  "type": "ប្រភេទ",
  "Video": "វីដេអូ",
  "Back to": "ត្រឡប់ទៅ",
  "Chapters": "ជំពូក",
  // "Chapters":"ជំពូក",
  "Chapter": "ជំពូក",
  "Topics": "ប្រធានបទ",
  "Under this": "នៅក្រោមនេះ",
  "Course": "វគ្គសិក្សា",
  "PDF": "ឯកសារ PDF",
  "VIDEO": "វីដេអូ",
  "Topic": "ប្រធានបទ",
  "Involved": "ពាក់ព័ន្ធ",
  "By": "ដោយ",
  "Welcome back": "សូមស្វាគមន៍ការត្រឡប់មកវិញ",
  "Data": "ទិន្នន័យ",
  "For": "សម្រាប់",
  "From Date": "ពីកាលបរិច្ឆេទ",
  "To Date": "ដល់កាលបរិច្ឆេទ",
  "TODAY'S": "ថ្ងៃនេះ",
  "ATTENDANCE": "វត្តមាន",
  "THIS": "នេះ",
  "MONTH": "ខែ",
  "LEAVE": "ច្បាប់",
  "TOTAL": "សរុប",
  "ORGANIZATIONS": "ក្រុមហ៊ុន",
  "ACTIVE": "សកម្ម",
  "EMPLOYEES": "និយោជិត",
  "Today's": "ថ្ងៃនេះ",
  "Project Head": "ប្រធានគម្រោង",
  "Contact No": "លេខទំនាក់ទំនង",
  "Add More": "បន្ថែម​ទៀត",
  "Guest": "ភ្ញៀវ",
  "Visit To": "ទៅជួប",
  "License Plate": "ផ្លាកលេខ",
  "Add Checkout Time": "បន្ថែមម៉ោងចេញ",
  "Business Visit": "ជួបអំពីរឿងអាជីវកម្ម",
  "Meeting": "ការប្រជុំ",
  "Personal Visit": "អំពីរឿងផ្ទាល់ខ្លួន",
  "Interview": "ការសម្ភាស",
  "Presentation": "ធ្វើបទ​បង្ហាញ",
  "Sales Visit": "ដំណើរទស្សនកិច្ចផ្នែកលក់",
  "Client Visit": "ដំណើរទស្សនកិច្ចរបស់អតិថិជន",
  "Supplier Visit": "ដំណើរទស្សនកិច្ចរបស់អ្នកផ្គត់ផ្គង់",
  //"Other":"ផ្សេងៗ",
  "Take": "យក",
  "HR": "ធនធានមនុស្ស",
  "Email Already Exists": "អ៊ីម៉ែលនេះមានរួចហើយ",
  "HR Code Already Exists": "កូដធនធានមនុស្សមានរួចហើយ",
  "Account Name Already Exists": "ឈ្មោះគណនីមានរួចហើយ",
  "Account Name should not be greater than 10 characters": "ឈ្មោះគណនីមិនគួរលើសពី 10 តួអក្សរ",
  "Database not found with given account name, Please create database first": "រកមិនឃើញមូលដ្ឋានទិន្នន័យដែលមានឈ្មោះគណនីដែលបានផ្ដល់ឱ្យទេ សូមបង្កើតមូលដ្ឋានទិន្នន័យជាមុនសិន",
  "Database not available to create HR admin": "មិនមានមូលដ្ឋានទិន្នន័យដើម្បីបង្កើតអ្នកគ្រប់គ្រងធនធានមនុស្សទេ",
  "Min.Amount": "ចំនួនទឹកប្រាក់អប្បបរមា",
  "Max.Amount": "ចំនួនទឹកប្រាក់អតិបរមា",
  "Base amount": "ចំនួនទឹកប្រាក់មូលដ្ឋាន",
  "Percentage": "ភាគរយ",
  "Tax": "ពន្ធ",
  "Dependence allowance ": "ប្រាក់ឧបត្ថម្ភសម្រាប់ភាពអាស្រ័យ",
  "Tax Bracket List": "បញ្ជីគ្រប់គ្រងពន្ធ",
  "Tax Bracket": "គ្រប់គ្រងពន្ធ",
  "Super User": "អ្នកប្រើប្រាស់ជាន់ខ្ពស់",
  "Day": "ថ្ងៃ",
  "Monday": "ថ្ងៃចន្ទ",
  "Rest-Time": "ម៉ោងសម្រាក",
  "tuesday": "ថ្ងៃអង្គារ",
  "Wednesday": "ថ្ងៃពុធ",
  "Thursday": "ថ្ងៃព្រហស្បតិ៍",
  "Friday": "ថ្ងៃសុក្រ",
  "Saturday": "ថ្ងៃសៅរ៍",
  "Sunday": "ថ្ងៃអាទិត្យ",
  "Signup": "ចុះ​ឈ្មោះ",
  "Requests": "សំណើ",
  "Reference": "យោង",
  "Account": "គណនី",
  "Personal": "ផ្ទាល់ខ្លួន",
  "Create": "បង្កើត",
  "user Roles": "តួនាទីអ្នកប្រើប្រាស់",
  "Task": "ភារកិច្ច",
  "In Progress": "កំពុង​ដំណើរការ",
  "Completed": "បានបញ្ចប់",
  "Logs Present in this Task so cant be deleted": "កំណត់ហេតុមានវត្តមាននៅក្នុងកិច្ចការនេះ ដូច្នេះមិនអាចលុបបានទេ",
  "Log Text": "កំណត់ហេតុអត្ថបទ",
  "Logs": "កំណត់ហេតុ",
  "Projects": "គម្រោង",
  "Member": "សមាជិក",
  "Moderate": "មធ្យម",
  "Low": "ទាប",
  "High": "ខ្ពស់",
  "Very High": "ខ្ពស់​ណាស់",
  "Priority": "អាទិភាព",
  "Visit": "ទស្សនា",
  "Weight": "ទំងន់",
  "Value": "តម្លៃ",
  "Score": "ពិន្ទុ",
  "Completion": "ការបញ្ចប់",
  "Uploaded image has not valid Height and Width": "រូបភាពដែលបានបង្ហោះមិនមានកម្ពស់ និងទទឹងត្រឹមត្រូវ",
  "Promotion": "ការផ្សព្វផ្សាយ",
  "Redirect": "ប្តូរទិស",
  "Task Presnt in this Project so cant be deleted": "ភារកិច្ចមានវត្តមាននៅក្នុងគម្រោងនេះ ដូច្នេះមិនអាចលុបបានទេ",
  "Now": "ឥឡូវ​នេះ",
  "Importance": "សារៈសំខាន់",
  "Performance Kpi": "ការបង្ហាញសូចនាករសំខាន់នៃការអនុវត្ត",
  "Please add the reason of delete": "សូមបន្ថែមមូលហេតុនៃការលុប",
  "Role": "តួនាទី",
  "Please select at least one module": "សូមជ្រើសរើសយ៉ាងហោចណាស់មួយ",
  "Analyst": "វិភាគ",
  "analytics": "ការវិភាគ",
  "Top Selling": "លក់ដាច់ជាងគេ",
  "Shop": "ហាង",
  "Cart": "កន្ត្រក",
  "File size less than 1 MB": "ទំហំឯកសារតិចជាង 1 MB",
  "Check Discount Price": "ពិនិត្យការបញ្ចុះតម្លៃ",
  "Required": "ទាមទារ",
  "Investigating": "កំពុងស៊ើបអង្កេត",
  "Incident": "ឧបទ្ទវហេតុ",
  "Job": "ការងារ",
  "Level": "កម្រិត",
  "Grade": "ថ្នាក់",
  "Attach": "ភ្ជាប់",
  "Functions": "មុខងារ",
  "Qualification": "កម្រិតវប្បធម៌",
  "Responsibilities": "ទំនួលខុសត្រូវ",
  "Reporting": "របាយការណ៍",
  "Perks": "អត្ថប្រយោជន៍",
  "Benefits": "ផលប្រយោជន៍",
  "Make-Model": "ធ្វើគំរូ",
  "Check IN/OUT": "ពិនិត្យចូល/ចេញ",
  "Please Upload the File": "សូមភ្ជាប់ឯកសារ",
  "No mid month": "មិនមានពាក់កណ្តាលខែទេ",
  "Mid Month": "ពាក់កណ្តាលខែ",
  "Payroll": "ប្រាក់បៀវត្សរ៍",
  "Process": "ដំណើរការ",
  "Bonus": "ប្រាក់រង្វាន់",
  "Working": "ធ្វើការ",
  "Test": "សាកល្បង",
  "Live": "រស់នៅ",
  "Line": "ជួរ",
  "Entry": "ការចូល",
  "Currency": "រូបិយប័ណ្ណ",
  "Rate": "អត្រា",

  "Banners": "ផ្ទាំងពាពិជ្ជកម្ម",
  "Orders": "កម្ម៉ង់",
  "Product": "ផលិតផល",
  "Pay Codes": "លេខកូដបង់ប្រាក់",
  "Comments": "មតិយោបល់",
  "Career": "អាជីព",
  "Profile": "ប្រវត្តិរូប",
  "Employee Presence": "វត្តមាននិយោជិត",
  "Employee Absence": "អវត្តមាននិយោជិត",
  "My Job Description": "ការពិពណ៌នាការងាររបស់ខ្ញុំ",
  "Job Description": "ការពិពណ៌នាការងារ",
  "Settings": "ការកំណត់",
  "Public Holidays": "ថ្ងៃឈប់សម្រាកសាធារណៈ",
  "Master Department": "នាយកដ្ឋាន",
  "Point Declaration": "ពិន្ទុសេចក្តីប្រកាស",
  "User Role": "តួនាទីអ្នកប្រើប្រាស់",
  "Admin Apps": "កម្មវិធីគ្រប់គ្រង",
  "Groups": "ក្រុម",
  "Employee Bulk Import": "ការ​នាំចូល​ច្រើន​របស់​បុគ្គលិក",
  "Guests": "ភ្ញៀវ",
  "Check In Out": "ពិនិត្យចូលនិងចេញ",
  "Incident Report": "របាយការណ៍​គ្រោះថ្នាក់",
  "Tasks": "ភារៈកិច្ច",
  "Work Plan": "ផែនការការងារ",
  "My": "របស់ខ្ញុំ",
  "Promotions": "ការផ្សព្វផ្សាយ",
  "Payroll Line Entry": "ការចូលបន្ទាត់ប្រាក់ខែ",
  "Employee Chat": "ការជជែកជាមួយនិយោជិត",
  "Messages": "សារ",
  "Training": "ការបណ្តុះបណ្តាល",
  "SignUp Requests": "សំណើរចុះឈ្មោះ",
  "My Profile": "ប្រវត្តិរូបរបស់ខ្ញុំ",
  "Employee Career": "អាជីពបុគ្គលិក",
  "All Job Post": "បង្ហោះការងារទាំងអស់",
  "Application List": "បញ្ជីពាក្យសុំ",
  "Job Frontend Url": "តំណរភ្ជាប់ការងារខាងមុខ",
  "Manage Shifts": "គ្រប់គ្រងកាលវិភាគ",
  "Shift Scheduler": "កំណត់ពេលកាលវិភាគ",
  "Shift Scheduler History": "ប្រវត្តិនៃការកំណត់កាលវិភាគ",
  "Shift Schedule": "ការផ្លាស់ប្តូរកាលវិភាគ",
  "Constants": "ថេរ",
  "No Preference": "គ្មានចំណូលចិត្ត",
  "Fresher": "ស្រស់ជាង",
  "Year": "ឆ្នាំ",
  "Short Description": "ការពិពណ៌នាខ្លី",
  "Long Description": "ការពិពណ៌នាវែង",
  "Add Thumbnail Image": "បន្ថែមរូបភាពតូច",
  "Enter Valid link": "បញ្ចូលតំណរត្រឹមត្រូវ",
  "In Stock": "នៅ​ក្នុង​ស្តុក",
  "Out Of Stock": "អស់ពី​ស្តុក",
  "In Active": "នៅក្នុងសកម្ម",
  "Ordered": "បានបញ្ជាទិញ",
  "Accepted": "ទទួលយក",
  "Rejected and Closed": "បដិសេធ និងបិទ",
  "Shipped/InTransit": "ដឹកជញ្ជូន/ឆ្លងកាត់",
  "Completed and Closed": "បានបញ្ចប់ និងបិទ",
  "Cancelled and Closed": "លុបចោល និងបិទ",
  "Order No": "លេខបញ្ជាទិញ",
  "Order On": "បញ្ជាទិញនៅលើ",
  "Product Feature": "លក្ខណៈពិសេសផលិតផល",
  "Subtotal": "សរុបរង",
  "No items in the cart": "មិនមានទំនិញនៅក្នុងរទេះទេ",
  "Place Order": "ដាក់បញ្ជា",
  "Change": "ផ្លាស់ប្តូរ",
  // "Select":"ជ្រើសរើស",
  "Language": "ភាសា",

  ////////////////////
  "Job Title": "ចំណងជើងការងារ",
  "All Employee": "និយោជិតទាំងអស់",
  "Day Leave": "ថ្ងៃឈប់សម្រាក",
  "System Logs": "កំណត់ហេតុចូលប្រព័ន្ធ",
  "Employee Documents": "ឯកសារនិយោជិត",
  "Leave Types": "ប្រភេទច្បាប់",
  "Allowance Type": "ប្រភេទប្រាក់ឧបត្ថម្ភ",
  "Allowance Code": "លេខកូដប្រាក់ឧបត្ថម្ភ",
  "Note Code": "លេខកូដចំណាំ",
  "Document Code": "លេខកូដឯកសារ",
  "Comment Category": "ប្រភេទមតិយោបល់",
  "Comment Type": "ប្រភេទមតិ",


  "My Shift Schedule": "កាលវិភាគរបស់ខ្ញុំ",
  "My Orders": "ការបញ្ជាទិញរបស់ខ្ញុំ",
  "Organization Blogs": "ពត៌មានរបស់អង្គភាព",
  "My Attendance": "វត្តមានរបស់ខ្ញុំ",
  "My Leaves": "ច្បាប់របស់ខ្ញុំ",
  "Acess Code": "លេខកូដចូលប្រើ",
  "My Points": "ពិន្ទុរបស់ខ្ញុំ",
  "My Documents": "ឯកសារ​របស់​ខ្ញុំ",
  "My Assests": "ទ្រព្យសម្បត្តិរបស់ខ្ញុំ",
  "My Payslips": "ចុងសន្លឹកបៀរវត្សរបស់ខ្ញុំ",
  "Sub Admin": "អ្នកគ្រប់គ្រងរង",
  "Manage Tax Brackets": "គ្រប់គ្រងប្រភេទអត្រាពន្ធ",
  "All Shifts": "កាលវិភាគទាំងអស់",
  "Shift Scheduler Import": "ការនាំចូលតារាងកាលវិភាគ",
  "Project Task": "គម្រោងភារៈកិច្ច",
  "Employee Training": "ការបណ្តុះបណ្តាលនិយោជិត",
  "Employee Shop": "ហាងនិយោជិត",
  "Product Category": "ប្រភេទ​ផលិតផល",
  "Products": "ផលិតផល",
  "Public Holiday": "ថ្ងៃសម្រាក​​សាធារណៈ",
  "Blog Type": "ប្រភេទពត៌មាន",
  "Job Type": "ប្រភេទ​ការងារ",
  "Course Type": "ប្រភេទវគ្គសិក្សា",
  "Annoucement Type": "ប្រភេទសេចក្តីជូនដំណឹង",


  "Add/Subtract": "បន្ថែម/ដក",
  "Mood": "អារម្មណ៍",
  "Negative Balance": "តុល្យភាពអវិជ្ជមាន",
  "Leave Bank Report": "Leave Bank Report",
  "Payroll Type": "Payroll Type",
  "Flat Salary": "Flat Salary",
  "Salary Attendance": "Salary Attendance",
  "Salary Attendance + OT": "Salary Attendance + OT",
  "Hourly Wages": "Hourly Wages",
  "Deduction": "Deduction",
  "Ajustment": "Ajustment",
  "Settlements": "Settlements",
  "Current Balance": "Current Balance",
  "Allowed Negative Balance": "Allowed Negative Balance",
  "No Record Found": "No Record Found",
  //"Work Schedular":"Work Schedular",



  ////////Date 23/2/2kl
  "Upcoming": "Upcoming",
  //"Today's":"Today's",
  "Absent": "Absent",
  "Apply": "Apply",
  "Database Host": "Database Host",
  "Database User": "Database User",
  "Database Password": "Database Password",
  "Primary Database Name": "Primary Database Name",
  "Payroll Database Name": "Payroll Database Name",
  "Language Transalation Type": "Language Transalation Type",
  "Google Transaltor": "Google Transaltor",
  "Custom": "Custom",
  "Default Language": "Default Language",
  "English": "English",
  "Khemer": "Khemer",
  "Chinese": "Chinese",
  "More than 1 organization cannot be added": "More than 1 organization cannot be added",
  "Organization Code already exists": "Organization Code already exists",
  "Add Payslip Header or Footer or organization logo": "Add Payslip Header or Footer or organization logo",
  "Company Policy": "Company Policy",
  "Employee Handbook": "Employee Handbook",
  "Payslip Header": "Payslip Header",
  "Payslip Footer": "Payslip Footer",
  "Color Code": "Color Code",
  // "Sequence":"Sequence",

  /****29 march */

  'Shifts': 'Shifts',
  "Visitor": "Visitor",
  "Work Scheduler": "Work Scheduler",
  "Work Scheduler History": "Work Scheduler History",
  "Work Scheduler Import": "Work Scheduler Import",
  "Vehicle": "Vehicle",
  "Total IN Visitor": "Total IN Visitor",



  /////////Date 28/4/23/////////
  "Seniority Pay": "Seniority Pay",
  "Convert to USD Dollar": "Convert to USD Dollar",
  //"":"",
  //'Absent':'Absent',




  // //"Documents":"Documents",
  // "Expired":"Expired",
  // "in":"in",
  // "days":"days",
  // "New":"New",
  // "Complaints":"Complaints",
  // "No":"No",
  // "Messages":"Messages",
  // "Arrived":"Arrived",
  // "Leaves":"Leaves",
  // "today":"today",
  // "My Profile":"My Profile",
  // "Sign out":"Sign out",
  // "Flat Salary":"Flat Salary",
  // "Salary Attendance":"Salary Attendance",
  // "OT":"OT",
  // "Hourly Wages":"Hourly Wages",
  // "NOTE:In Time and Out Time in 24 Hrs Format":"NOTE:In Time and Out Time in 24 Hrs Format",
  // "Please select a date":"Please select a date",
  // "Rest":"Rest",
  // "Early Leaving":"Early Leaving",
  // "Over Time":"Over Time",
  // "Time Late":"Time Late",
  // "Previous":"Previous",
  // "Records":"Records",
  // "Check IN Out Records":"Check IN Out Records",
  // "Check IN":"Check IN",
  // "Select Status":"Select Status",
  // "ADD":"ADD",
  // "Export":"Export",
  // "Active":"Active",
  // "Title":"Title",
  // "Address":"Address",
  // "Owner":"Owner",
  // "Status":"Status",
  // "Action":"Action",
  // "Photo":"Photo",
  // "Select":"Select",
  // "User":"User",
  // "Employee Local Name":"Employee Local Name",
  // "Vehicle":"Vehicle",
  // "Notice Pay":"Notice Pay",
  // "See":"See",
  // "Not found":"Not found",
  // "Upcoming":"Upcoming",
  // "Absent":"Absent",
  // "Expire With in Days":"Expire With in Days",
  // "Leave Bank":"Leave Bank",
  // "To":"To",
  // "Outstanding":"Outstanding",
  // "Mood":"Mood",
  // "No options":"No options",
  // "General":"General",
  // "Very Sad":"Very Sad",
  // "Sad":"Sad",
  // "Just OK":"Just OK",
  // "Good":"Good",
  // "Fantastic":"Fantastic",
  // "Document Type List":"Document Type List",
  // "Dashboard":"Dashboard",
  // "Doc Type":"Doc Type",
  // "All":"All",
  // "Expiry By":"Expiry By",
  // "Days to expire";"Days to expire",
  // "All Expiry Type":"All Expiry Type",
  // "Days to expire":"Days to expire",
  // "Already Expired":"Already Expired",
  // "All Document Type":"All Document Type",
  // "In-Active":"In-Active",
  // "Select Department":"Select Department",
  // "Select Employee":"Select Employee",
  // "Work Schedular":"Work Schedular",
  // "Work Schedular History":"Work Schedular History",
  // "Work Schedular Import":"Work Schedular Import",
  // "Calender View":"Calender View",
  // "Choose File":"Choose File",
  // "No file chosen":"No file chosen",
  // "Download Sample File":"Download Sample File",
  // "Download Sample CSV File":"Download Sample CSV File",
  // "Upload":"Upload",
  // "Preview Of Shift Scheduler Table":"Preview Of Shift Scheduler Table",
  // "Shift Schduler of Parent Table":"Shift Schduler of Parent Table",
  // "All Organization":"All Organization",
  // "Test Payroll":"Test Payroll",
  // "Rate":"Rate",
  // "View":"View",
  // "Payslip":"Payslip",
  // "All Payroll Entry":"All Payroll Entry",
  // "Payroll Title":"Payroll Title",
  // "Employee ID":"Employee ID",
  // "Employee Name":"Employee Name",
  // "Department":"Department",
  // "Designation":"Designation",
  // "Color Code":"Color Code",
  // "Multiplier":"Multiplier",
  // "Pay Code":"Pay Code",
  // "Taxcode":"Taxcode",
  // "Project":"Project",
  // "In Progress":"In Progress",
  // "Closed":"Closed",
  // "Project ID":"Project ID",
  // "Status":"Status",
  // "Open":"Open",
  // "Id":"Id",
  // "Job Title":"Job Title",
  // "Job ID":"Job ID",
  // "Description":"Description",
  // "Close Date":"Close Date",
  // "No Of Candidates Applied":"No Of Candidates Applied",
  // "Number Of":"Number Of",
  // "Url":"Url",
  // "Normal":"Normal",
  // "Document Uploaded":"Document Uploaded",
  // "Public Jobs":"Public Jobs",
  // "Private Jobs":"Private Jobs",
  // "Course List":"Course List",
  // "Course":"Course",
  // "Select Course Type":"Select Course Type",
  // "Departments":"Departments",
  // "Description":"Description",
  // "Cancel":"Cancel",
  // "Chat":"Chat",
  // "Select Department":"Select Department",
  // "Select Organization":"Select Organization",
  // "Load All":"Load All",
  // "SKU":"SKU",
  // "Cost":"Cost",
  // "Price":"Price",
  // "Discount Price":"Discount Price",
  // "Link":"Link",
  // "Brand":"Brand",
  // "Top List":"Top List",
  // "Image Size":"Image Size",
  // "Documents Uploaded":"Documents Uploaded",
  // "Select Status":"Select Status",
  // "No":"No",
  // "View resume":"View resume",
  // "Applied For":"Applied For",
  // "Applicant":"Applicant",
  // "Application Name":"Application Name",
  // "Applicant Mobile":"Applicant Mobile",
  // "Applicant Email":"Applicant Email",
  // "Status":"Status",
  // "Remark":"Remark",
  // "Thumbnail":"Thumbnail",
  // "Business Promotion":"Business Promotion",
  // "Visitor":"Visitor",
  // "Total IN Visitor":"Total IN Visitor",
  // "QR Code":"QR Code",
  // "Phone":"Phone",
  // "Send QR Code":"Send QR Code",
  // "Not Checked Out":"Not Checked Out",
  // "History":"History",
  // "Open":"Open",
  // "Reported":"Reported",
  // "Created On":"Created On",
  // "Incident From":"Incident From",
  // "Incident Title":"Incident Title",
  // "Incident Form":"Incident Form",
  // "Level":"Level",
  // "Remark":"Remark",
  // "Status":"Status",
  // "Description of incident":"Description of incident",
  // "Check In":"Check In",
  // "code":"code",
  // "Address Line 1":"Address Line 1",
  // "City":"City",
  // "State/Province":"State/Province",
  // "Country":"Country",
  // "Attendance Radius (In Meters)":"Attendance Radius (In Meters)",
  // "Company Policy":"Company Policy",
  // "Employee Handbook":"Employee Handbook",
  // "Payslip Header":"Payslip Header",
  // "Payslip Footer":"Payslip Footer",
  // "Height":"Height",
  // "Width":"Width",
  // "ID Template":"ID Template",
  // "Select Organization Logo":"Select Organization Logo",
  // "Public Holiday":"Public Holiday",
  // "Holiday":"Holiday",
  // "Apply Shift Schduler":"Apply Shift Schduler",
  // "Pay":"Pay",
  // "Name":"Name",
  // "Shifts":"Shifts",
  // "Pay":"Pay",
  // "Negative Balance":"Negative Balance",
  // "This field is invalid":"This field is invalid",
  // "Leave Type":"Leave Type",
  // "Admin app":"Admin app",
  // "URL":"URL",
  // "Asset":"Asset",
  // "Allowance Code":"Allowance Code",
  // "Description":"Description",
  // "Cancel":"Cancel",
  // "Add":"Add",
  // "Document Type":"Document Type",
  // "Employee Group":"Employee Group",
  // "Job ID":"Job ID",
  // "Annoucement":"Annoucement",
  // "Course Type":"Course Type",
  // "System Logs":"System Logs",
  // "User":"User",
  // "User IP":"User IP",
  // "Location":"Location",
  // "Operation":"Operation",
  // "Affected Table":"Affected Table",
  // "Rows":"Rows",
  // "Timestamp":"Timestamp",




  ////Date 20/6/23
  "Point": "观点",



























  //Manage Tax Brackets             ,  
};
// }
// else
// {



// module.exports = {
//   "Employees List":
//     "Employees List",
//     "Dashboard":
//     "Dashboard",
//     "Select Organization":
//     "Select Organization",
//     "Status":
//     "Status",
//     "Select Department":
//     "Select Department",
//     "Select Positions":
//     "Select Positions",
//     "Export":
//     "Export",
//     "Import +":
//     "Import +",
//     "Update Qrcode":
//     "Update Qrcode",
//     "Search":
//     "Search",
// };

// }


