import React, { PureComponent } from "react";
import { Redirect } from 'react-router';
import { Link } from "react-router-dom";
import * as myConstClass from '../../constant.js';
import $ from 'jquery';
import { toastr } from "react-redux-toastr";
import {
  Button,
  Card,
  CardBody,
  Label,
  Row
} from "reactstrap";
import {
  AvForm,
  AvGroup,
  AvInput
} from "availity-reactstrap-validation";
import {
  T,
  Config,
  TF
} from "react-translator-component";
//Config.default = localStorage.getItem("lang");


class LogInForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hrm: '',
      showPassword: false,
      toDashboard: false,
      timeOut: 5000,
      showCloseButton: true,
      progressBar: true,
      position: "top-right",
      complaint_against: [],
      module_array: [],
    };
    this.login = this.login.bind(this);
  }


  onFormSubmit = e => {
    // alert();
    //new changes
    e.preventDefault();
    //console.log('this.refs',this.props.history.push('/dashboard_default'));
    const formData = {};
    for (const field in this.refs) {
      formData[field] = this.refs[field].value;
    }
    console.log('-->', formData);
    fetch(myConstClass.BASE_URL + `login/verifyLogin/`, {
      method: 'POST',
      body: JSON.stringify($('#pform').serializeArray()),
    }).then(response => response.json()).then((response) => {

      if (response == 2) {
        toastr.error(
          'Error',
          "Please enter email..!",
          this.options
        )
      } else if (response == 3) {
        toastr.error(
          'Error',
          "Please enter password ..!",
          this.options
        )
      }
      else if (response == 4) {
        toastr.error(
          'Error',
          "Login Failed ...!",
          this.options
        )
      } else if (response !== false) {


















        var sarray = response.split('-');
        localStorage.setItem('login_type', sarray['8']);
        localStorage.setItem('role_modules', sarray['9']);
        localStorage.setItem('manage_app', sarray['6']);
        localStorage.setItem('login_id', sarray['7']);
        localStorage.setItem('package', sarray['4']);
        localStorage.setItem('manage_promotions', sarray['3']);
        localStorage.setItem('uname', sarray['2']);
        localStorage.setItem('utype', sarray['1']);
        localStorage.setItem('session_id', sarray['0']);
        localStorage.setItem('company_name', sarray['10']);
        localStorage.setItem('departementhead', sarray['11']);
        localStorage.setItem('is_subadmin', sarray['12']);
        localStorage.setItem('sub_admin_role', sarray['13']);

        localStorage.setItem('sub_admin_roles', sarray['14']);

        localStorage.setItem('is_super_user', sarray['15']);
        localStorage.setItem('hr_admin_roles', sarray['16']);
        //alert();
        localStorage.setItem('is_google_transaltor', sarray['17']);
        localStorage.setItem('lang', sarray['18']);

        // alert(sarray['2']);
        var admin_id = localStorage.getItem('uname');

        fetch(myConstClass.BASE_URL + 'organization/getmoduleshradminAgainstInfo/' + admin_id)
          .then(response => response.json())
          .then(response => {

            //alert(response);
            //let newArray = response.slice();
            //this.setState({complaint_against: response})
            localStorage.setItem('hr_work_plan', response[0]);
            localStorage.setItem('hr_emp_careeer', response[1]);
            localStorage.setItem('hr_emp_training', response[2]);
            localStorage.setItem('hr_emp_msg', response[3]);
            localStorage.setItem('hr_emp_shop', response[4]);
            localStorage.setItem('hr_emp_promotions', response[5]);
            localStorage.setItem('hr_emp_annoucements', response[6]);
            localStorage.setItem('hr_emp_blogs', response[7]);
            localStorage.setItem('hr_emp_guests', response[8]);

            //alert(response[8]);

          }).catch(err => console.log(err));




        // fetch(myConstClass.BASE_URL+'organization/getmodulesAgainstInfo/'+sarray['13'])
        // .then(response => response.json())
        // .then(response =>{
        //   let i=1;  
        //  // alert(response);
        //   localStorage.setItem("modulenames",response);
        // this.setState({module_array: response})}).catch(err => console.log(err));
        // // localstorage.modulenames = JSON.stringify(module_array);
        // var storedmoduleNames = JSON.parse(localStorage.modulenames);





        //  localStorage.setItem("modulenames", JSON.stringify(this.state.module_array));

        //...
        // var storedNames = JSON.parse(localStorage.getItem("modulenames"));







        //	  alert(storedNames);
        var admin_id = localStorage.getItem('uname');
        // alert(admin_id);
        var login_type = localStorage.getItem('login_type');
        var login_id = localStorage.getItem('login_id');
        fetch(myConstClass.BASE_URL + 'user/getunreadMessagesCount/' + admin_id + '/' + login_id + '/' + login_type)
          .then(response => response.json())
          .then(response => {

            //const notifications = [];
            //this.setState({leave_types: response})

            //alert(response);
            //alert(response);


            localStorage.setItem('mcount', response);
            //alert(mcount);
            /*    toastr.info(
                                'Message',
                                "You have a new message",
                                this.options
                              ) */



          }).catch(err => console.log(err))



        fetch(myConstClass.BASE_URL + 'user/getunreadOrderCount/' + admin_id + '/' + login_type)
          .then(response => response.json())
          .then(response => {

            //const notifications = [];
            //this.setState({leave_types: response})

            //alert(response);
            //alert(response);


            localStorage.setItem('ocount', response);
            //alert(mcount);
            /*    toastr.info(
                                'Message',
                                "You have a new message",
                                this.options
                              ) */



          }).catch(err => console.log(err))






        // alert(mcount);
        // alert(mcount);


        this.setState(() => ({
          toDashboard: true
        }))
      }
    })
      .catch();
  }
  componentDidMount() {

    // encodeURIComponent(myUrl)
    // alert("hii");
    var admin_id = localStorage.getItem('uname');
    // if(admin_id != null)
    // {
    //   localStorage.clear();
    // }
    fetch(myConstClass.BASE_URL + 'login/get_hrm/' + window.location.href)
      .then(response => response.json())
      .then(response => { this.setState({ hrm: response }) }).catch(err => console.log(err))


  }
  logine(e) {
    // alert('signup');
    // e.preventDefault();
    //this.props.history.push('/signup');
    localStorage.setItem('signup', '1');
    window.location.href = '/signup';
  }
  login(e) {
    e.preventDefault();


    //console.log('this.refs',this.props.history.push('/dashboard_default'));
    const formData = {};
    for (const field in this.refs) {
      formData[field] = this.refs[field].value;
    }
    console.log('-->', formData);

    fetch(myConstClass.LOGIN_URL + `login/auth/`, {
      method: 'POST',
      body: JSON.stringify($('#pform').serializeArray()),
    }).then(response => response.json()).then((response) => {

      if (response == 2) {
        toastr.error(
          TF("{0}", T("Error")),
          TF("{0} {1} {2}...!", T("Please"), T("enter"), T("Email ID")),
          this.options
        )
      } else if (response == 3) {
        toastr.error(
          TF("{0}", T("Error")),
          TF("{0} {1} {2}...!", T("Please"), T("enter"), T("password")),
          this.options
        )
      } else if (response == 4) {
        toastr.error(
          TF("{0}", T("Error")),
          TF("{0} {1}...!", T("Incorrect"), T("Credentials")),
          this.options
        )
      } else if (response == 5) {
        toastr.error(
          TF("{0}", T("Error")),
          TF("{0} {1}...!", T("Incorrect"), T("Password")),
          this.options
        )
      } else if (response == 6) {
        toastr.error(
          TF("{0}", T("Error")),
          TF("{0} {1}...!", T("Incorrect"), T("Email ID")),
          this.options
        )
      } else if (response == 7) {
        toastr.error(
          TF("{0}", T("Error")),
          TF("{0} {1}...!", T("Login"), T("Unsuccessful")),
          this.options
        )
      } else if (response !== false) {
        var sarray = response.split('-');

        // alert(sarray['2']);
        //  alert(sarray['2']);
        //alert(sarray['8']);alert(sarray['7']);
        localStorage.setItem('signup', '0');
        localStorage.setItem('login_type', sarray['8']);
        localStorage.setItem('role_modules', sarray['9']);
        localStorage.setItem('login_id', sarray['7']);
        localStorage.setItem('manage_app', sarray['6']);
        localStorage.setItem('url', sarray['5']);
        localStorage.setItem('package', sarray['4']);
        localStorage.setItem('manage_promotions', sarray['3']);
        localStorage.setItem('uname', sarray['2']);
        localStorage.setItem('utype', sarray['1']);
        localStorage.setItem('session_id', sarray['0']);
        localStorage.setItem('company_name', sarray['10']);
        localStorage.setItem('departementhead', sarray['11']);
        localStorage.setItem('is_subadmin', sarray['12']);
        localStorage.setItem('sub_admin_role', sarray['13']);

        localStorage.setItem('sub_admin_roles', sarray['14']);
        localStorage.setItem('is_super_user', sarray['15']);
        localStorage.setItem('hr_admin_roles', sarray['16']);
        //alert();
        localStorage.setItem('is_google_transaltor', sarray['17']);
        localStorage.setItem('lang', sarray['18']);


        Config.default = localStorage.setItem('lang', sarray['18']);


        localStorage.setItem('8', TF("{0}", T("My Profile")));

        localStorage.setItem('Dashboard', TF("{0}", T("Dashboard")));
        localStorage.setItem('2', TF("{0}", T("Job Frontend Url")));
        // alert( localStorage.getItem('2'));
        // localStorage.setItem('3', T("Career"));
        localStorage.setItem('4', TF("{0}", T("Career")));
        localStorage.setItem('5', TF("{0} {1}", T("Guests"), T("List")));
        localStorage.setItem('6', TF("{0} {1}", TF("{0} {1}", T("Employee"), T("List"))));
        // localStorage.setItem('7', TF("{0}", T("My Profile")));

        localStorage.setItem('9', TF("{0}", T("Signup Requests")));
        localStorage.setItem('10', TF("{0}", T("System Logs")));
        localStorage.setItem('11', TF("{0} {1}", T("Update"), T("Profile")));
        localStorage.setItem('12', TF("{0} {1}", T("All"), T("Employee")));

        localStorage.setItem('13', TF("{0}", T("Comments")));
        localStorage.setItem('14', TF("{0}", T("Employee Presence")));
        localStorage.setItem('15', TF("{0}", T("Employee Absence")));
        localStorage.setItem('16', TF("{0} {1}", T("Day"), T("Leave")));
        localStorage.setItem('17', TF("{0}", T("Sub Admin")));


        localStorage.setItem('18', TF("{0}", T("Security")));
        localStorage.setItem('19', TF("{0} {1}", T("Employee"), T("Documents")));
        localStorage.setItem('20', T("My Job Description"));
        localStorage.setItem('21', TF("{0}", T("Settings")));
        localStorage.setItem('22', TF("{0}", T("Organization")));


        localStorage.setItem('23', TF("{0}", T("Security")));
        localStorage.setItem('24', TF("{0}", T("Master Department")));
        localStorage.setItem('25', T("Point Declaration"));
        localStorage.setItem('26', TF("{0}", T("User Role")));
        localStorage.setItem('27', TF("{0}", T("Organization")));


        localStorage.setItem('28', TF("{0} {1}", T("Leave"), T("Types")));
        localStorage.setItem('29', TF("{0}", T("Admin Apps")));
        localStorage.setItem('30', TF("{0}", T("Assets")));
        localStorage.setItem('31', TF("{0} {1}", T("Allowance"), T("Type")));
        localStorage.setItem('32', TF("{0} {1}", T("Allowance"), T("Code")));

        localStorage.setItem('33', TF("{0} {1}", T("Note"), T("Code")));
        localStorage.setItem('34', TF("{0} {1}", T("Document"), T("Code")));
        localStorage.setItem('35', TF("{0} {1}", T("Comment"), T("Category"))
        );
        localStorage.setItem('36', TF("{0} {1}", T("Comment"), T("Type")));
        localStorage.setItem('37', TF("{0} {1}", T("Blog"), T("Type")));

        localStorage.setItem('36', TF("{0} {1}", T("Comment"), T("Type")));
        localStorage.setItem('37', TF("{0} {1}", T("Blog"), T("Type")));
        localStorage.setItem('38', TF("{0} {1}", T("Comment"), T("Type")));
        localStorage.setItem('39', TF("{0} {1}", T("Job"), T("Type")));



        localStorage.setItem('40', TF("{0} {1}", T("Annoucement"), T("Type")));
        localStorage.setItem('41', TF("{0} {1}", T("Course"), T("Type")));

        localStorage.setItem('42', TF("{0}", T("Payroll Line Entry Admin")));
        localStorage.setItem('43', TF("{0} {1}", T("Job"), T("Type")));
        localStorage.setItem('44', TF("{0}", T("Public Holidays")));
        localStorage.setItem('45', TF("{0}", T("Master Department")));

        localStorage.setItem('46', TF("{0}", T("Point Declaration")));
        localStorage.setItem('47', TF("{0}", T("Job Description")));
        localStorage.setItem('48', TF("{0}", T("User Role")));
        localStorage.setItem('49', TF("{0} {1}", T("Admin"), T("Apps")));

        localStorage.setItem('50', TF("{0}", T("Assets")));
        localStorage.setItem('51', TF("{0} {1}", T("Allowance"), T("Type")));
        localStorage.setItem('52', TF("{0} {1}", T("Allowance"), T("Code")));
        localStorage.setItem('53', TF("{0} {1}", T("Note"), T("Code")));


        localStorage.setItem('54', TF("{0} {1}", T("Document"), T("Type")));
        localStorage.setItem('55', TF("{0} {1}", T("Employee"), T("Groups")));
        localStorage.setItem('56', TF("{0} {1}", T("Complaint"), T("Category")));
        localStorage.setItem('57', TF("{0} {1}", T("Complaint"), T("Category")));

        localStorage.setItem('58', TF("{0} {1}", T("Blog"), T("Type")));
        localStorage.setItem('59', TF("{0} {1}", T("Job"), T("Type")));
        localStorage.setItem('60', TF("{0} {1}", T("Annoucement"), T("Type")));
        localStorage.setItem('61', TF("{0} {1}", T("Course"), T("Type")));




        localStorage.setItem('62', TF("{0}", T("Employee")));
        localStorage.setItem('63', TF("{0}", T("Employee Bulk Import")));
        localStorage.setItem('64', TF("{0}", T("Attendance")));
        localStorage.setItem('65', TF("{0}", T("Guests")));

        localStorage.setItem('66', TF("{0}", T("Check In Out")));
        localStorage.setItem('67', TF("{0}", T("Incident Report")));
        localStorage.setItem('68', TF("{0}", T("Guests")));
        localStorage.setItem('69', TF("{0} {1}", T("Course"), T("Type")));

        localStorage.setItem('70', TF("{0}", T("Projects")));

        localStorage.setItem('71', TF("{0}", T("Tasks")));

        localStorage.setItem('72', TF("{0} {1}", T("Work"), T("Plan")));
        localStorage.setItem('73', TF("{0}", T("Employee Presence")));

        localStorage.setItem('74', TF("{0}", T("Employee Absence")));
        localStorage.setItem('75', TF("{0} {1}", T("My"), T("Leaves")));


        localStorage.setItem('76', TF("{0} {1}", T("Work"), T("Plan")));
        localStorage.setItem('77', TF("{0}", T("Employee Presence")));

        localStorage.setItem('78', TF("{0}", T("Employee Absence")));
        localStorage.setItem('75', TF("{0} {1}", T("My"), T("Leaves")));
        localStorage.setItem('76', TF("{0} {1}", T("Day"), T("Leave")));
        localStorage.setItem('77', TF("{0}", T("Announcements")));

        localStorage.setItem('79', TF("{0}", T("Promotions")));
        localStorage.setItem('80', TF("{0} {1}", T("My"), T("Leaves")));
        localStorage.setItem('81', TF("{0} {1}", T("Day"), T("Leave")));
        localStorage.setItem('82', TF("{0}", T("Announcements")));
        localStorage.setItem('83', TF("{0} {1}", T("Admin"), T("Apps")));


        localStorage.setItem('84', TF("{0}", T("Payroll Line Entry")));
        localStorage.setItem('85', TF("{0}", T("Banners")));
        localStorage.setItem('86', TF("{0} {1}", T("Employee"), T("List")));
        localStorage.setItem('87', TF("{0} {1} {2}", T("Incident"), T("Report"), T("List")));
        localStorage.setItem('88', TF("{0} {1}", T("Employee"), T("Chat")));
        localStorage.setItem('89', TF("{0}", T("Messages")));

        localStorage.setItem('90', TF("{0} {1}", T("Employee"), T("Training")));
        localStorage.setItem('91', TF("{0}", T("Course")));
        localStorage.setItem('92', TF("{0}", T("Training")));

        localStorage.setItem('93', TF("{0}", T("Blogs")));
        localStorage.setItem('94', TF("{0} {1}", T("Organization"), T("Blogs")));

        localStorage.setItem('96', TF("{0}", T("SignUp Requests")));
        localStorage.setItem('95', TF("{0} {1}", T("User"), T("Role")));

        localStorage.setItem('97', TF("{0} {1}", T("HR"), T("Admin")));
        localStorage.setItem('98', TF("{0} {1}", T("My profile")));


        localStorage.setItem('99', TF("{0}", T("Employee Career")));
        localStorage.setItem('100', TF("{0} {1}", T("User"), T("Role")));

        localStorage.setItem('101', TF("{0} {1}", T("HR"), T("Admin")));
        localStorage.setItem('102', TF("{0}", T("Employee Career")));


        localStorage.setItem('103', TF("{0}", T("All Job Post")));
        localStorage.setItem('104', TF("{0}", T("Application List")));

        localStorage.setItem('105', TF("{0}", T("Employee Bulk Import")));
        localStorage.setItem('106', TF("{0}", T("Manage Shifts")));

        localStorage.setItem('107', TF("{0}", T("All Shifts")));
        localStorage.setItem('108', TF("{0}", T("Shift Scheduler")));
        localStorage.setItem('109', TF("{0}", T("Shift Scheduler History")));
        localStorage.setItem('110', TF("{0}", T("Shift Scheduler Import")));


        localStorage.setItem('111', TF("{0}", T("Payroll")));
        localStorage.setItem('112', TF("{0}", T("Manage Tax Brackets")));
        localStorage.setItem('113', TF("{0}", T("Payslips")));
        localStorage.setItem('114', TF("{0}", T("Pay Codes")));


        localStorage.setItem('115', TF("{0} {1}", T("My"), T("Shift Schedule")));
        localStorage.setItem('116', TF("{0} {1}", T("My"), T("Payslips")));
        localStorage.setItem('117', TF("{0} {1}", T("Access"), T("Code")));
        localStorage.setItem('118', TF("{0} {1}", T("My"), T("points")));
        localStorage.setItem('119', TF("{0} {1}", T("My"), T("Assests")));
        localStorage.setItem('120', TF("{0}", T("Shop")));
        localStorage.setItem('121', TF("{0} {1}", T("My"), T("Documents")));
        localStorage.setItem('122', TF("{0}", T("Super User")));
        localStorage.setItem('123', TF("{0} {1}", T("Employee"), T("Shop")));
        localStorage.setItem('124', TF("{0} {1}", T("Product"), T("Category")));
        localStorage.setItem('126', TF("{0}", T("Orders")));
        localStorage.setItem('125', TF("{0}", T("Products")));
        localStorage.setItem('126', TF("{0}", T("All Job Posts")));







        //alert("in sign in");


        //var name=TF("{0}",T("Dashboard"));
        localStorage.setItem('8', TF("{0}", T("My Profile")));

        //alert( TF("{0}", T("Dashboard")));
        //alert(sarray['16']);
        if (sarray['16'] != '0') {
          const myArraymodules = sarray['16'].split(",");
          if (myArraymodules != undefined) {
            if (myArraymodules[0] == '1') {
              localStorage.setItem('hr_work_plan', true);
            }
            else {
              localStorage.setItem('hr_work_plan', false);
            }

            if (myArraymodules[1] == '1') {
              localStorage.setItem('hr_emp_careeer', true);
            }
            else {
              localStorage.setItem('hr_emp_careeer', false);
            }

            if (myArraymodules[2] == '1') {
              localStorage.setItem('hr_emp_training', true);
            }
            else {
              localStorage.setItem('hr_emp_training', false);
            }

            if (myArraymodules[3] == '1') {
              localStorage.setItem('hr_emp_msg', true);
            }
            else {
              localStorage.setItem('hr_emp_msg', false);
            }

            if (myArraymodules[4] == '1') {
              localStorage.setItem('hr_emp_shop', true);
            }
            else {
              localStorage.setItem('hr_emp_shop', false);
            }


            if (myArraymodules[5] == '1') {
              localStorage.setItem('hr_emp_promotions', true);
            }
            else {
              localStorage.setItem('hr_emp_promotions', false);
            }


            if (myArraymodules[6] == '1') {
              localStorage.setItem('hr_emp_annoucements', true);
            }
            else {
              localStorage.setItem('hr_emp_annoucements', false);
            }

            if (myArraymodules[7] == '1') {
              localStorage.setItem('hr_emp_blogs', true);
            }
            else {
              localStorage.setItem('hr_emp_blogs', false);
            }

            if (myArraymodules[8] == '1') {
              localStorage.setItem('hr_emp_guests', true);
            }
            else {
              localStorage.setItem('hr_emp_guests', false);
            }




            window.location.reload(false);






          }
        }






        // fetch(myConstClass.BASE_URL+'organization/getmodulesAgainstInfo/'+sarray['13'])
        // .then(response => response.json())
        // .then(response =>{
        //   let i=1;  
        // //  alert(response);
        //   localStorage.setItem("modulenames",response);
        // this.setState({module_array: response})}).catch(err => console.log(err));
        // // localstorage.modulenames = JSON.stringify(module_array);
        // var storedmoduleNames = JSON.parse(localStorage.modulenames);







        //...
        // var storedNames = JSON.parse(localStorage.getItem("modulenames"));







        //alert(storedNames);






        this.setState(() => ({
          toDashboard: true
        }))




        var admin_id = localStorage.getItem('uname');

        fetch(myConstClass.BASE_URL + 'organization/getmoduleshradminAgainstInfo/' + admin_id)
          .then(response => response.json())
          .then(response => {

            //alert(response);
            //let newArray = response.slice();
            //this.setState({complaint_against: response})
            localStorage.setItem('hr_work_plan', response[0]);
            localStorage.setItem('hr_emp_careeer', response[1]);
            localStorage.setItem('hr_emp_training', response[2]);
            localStorage.setItem('hr_emp_msg', response[3]);
            localStorage.setItem('hr_emp_shop', response[4]);
            localStorage.setItem('hr_emp_promotions', response[5]);
            localStorage.setItem('hr_emp_annoucements', response[6]);
            localStorage.setItem('hr_emp_blogs', response[7]);
            localStorage.setItem('hr_emp_guests', response[8]);

            //alert(response[8]);

          }).catch(err => console.log(err));














      } else {
        toastr.error(
          TF("{0}", T("Error")),
          TF("{0} {1}...!", T("Login"), T("Unsuccessful")),
          this.options
        )
      }
    })
      .catch();
  }

  render() {
    // var url = window.location.href;
    // var str = url.split("/");
    // let ten_path;
    // let file_ten_path;
    // if(str[4] != '' && str[3] != ''){
    // ten_path = str[3]+'/'+ str[4];
    // file_ten_path  =  "/"+ten_path+'/dashboard'; 
    // } else {
    // ten_path = '';
    // file_ten_path='/dashboard';
    // }    



    let file_ten_path;
    let path1;
    let path2;
    let path3;
    let path4;
    // let url;

    var tenant_url = localStorage.getItem('url');
    var login_type = localStorage.getItem('login_type');//alert(login_type);
    var joburl = localStorage.getItem('joburl');
    var joburlcomp = localStorage.getItem('joburlcomp');
    var admin_id = localStorage.getItem('uname');





    fetch(myConstClass.BASE_URL + 'organization/getmoduleshradminAgainstInfo/' + admin_id)
      .then(response => response.json())
      .then(response => {

        // alert(response);
        //let newArray = response.slice();
        //this.setState({complaint_against: response})
        localStorage.setItem('hr_work_plan', response[0]);
        localStorage.setItem('hr_emp_careeer', response[1]);
        localStorage.setItem('hr_emp_training', response[2]);
        localStorage.setItem('hr_emp_msg', response[3]);
        localStorage.setItem('hr_emp_shop', response[4]);
        localStorage.setItem('hr_emp_promotions', response[5]);
        localStorage.setItem('hr_emp_annoucements', response[6]);
        localStorage.setItem('hr_emp_blogs', response[7]);
        localStorage.setItem('hr_emp_guests', response[8]);

        //alert(response[8]);

      }).catch(err => console.log(err));



    if (window.localStorage) {
      if (!localStorage.getItem('firstLoad')) {
        localStorage['firstLoad'] = true;
        window.location.reload();
      }
      else
        localStorage.removeItem('firstLoad');
    }













    if (tenant_url) {
      if (login_type == 'emp') {

        if (joburl == '1') {
          file_ten_path = myConstClass.CARREERCOMPANY_URL + '/' + joburlcomp;

        }
        else {


          file_ten_path = tenant_url + 'attendance/checkinout';
        }
      }

      else if (login_type == 'security') {
        //alert('hii');
        //	file_ten_path =tenant_url+"guests/listing";
        var hr_emp_guests = localStorage.getItem('hr_emp_guests');
        if (hr_emp_guests == 'true') {
          file_ten_path = "/" + tenant_url + "guests/listing";

        }
        else {
          file_ten_path = "/" + tenant_url + "attendance/ListingCheckoutSecurity";

        }


      }
      else {
        file_ten_path = tenant_url + 'dashboard';
      }

    } else {
      var url = window.location.pathname;
      var str = url.split("/");
      if (str[1] == 't') {
        if (str[1] && str[1] != 'undefined') {
          path1 = str[1] + '/';
        }
        if (str[2] && str[2] != 'undefined' && str[2] != 't') {
          path2 = str[2] + '/';
        }
        if (str[3] && str[3] != 'undefined') {
          path3 = str[3];
        } else {
          path3 = '';
        }

        // alert("A- "+path1+'  '+path2+'  '+path3);
        file_ten_path = path2 + path3 + 'dashboard';
      } else {
        if (str[1] && str[1] != 'undefined') {
          path1 = str[1] + '/';

        }

        if (str[2] && str[2] != 'undefined') {
          path2 = str[2];
        } else {
          path2 = 'index.php';
        }

        if (str[3] && str[3] != 'undefined') {
          path3 = str[3] + '/';
        } else {
          path3 = '';
        }

        if (str[4] && str[4] != 'undefined') {
          path4 = str[4] + '/';
        } else {
          path4 = '';
        }

        file_ten_path = path1 + path2 + '/' + path3 + path4 + 'dashboard';
      }


    }


    //////////////////



    // if(str[1] && str[1] != 'undefined' &&  str[2] && str[2] != 'undefined' ){
    //   ten_path = str[1]+'/'+ str[2]; 
    //   file_ten_path  = "/"+ten_path ;
    //   dashboardR = "/"+ten_path+"/dashboard";
    // } else if(str[1] && str[1] != 'undefined'){
    //   ten_path = str[1]; 
    //   file_ten_path  = "/"+ten_path ;
    //   dashboardR = "/"+ten_path+"/dashboard";
    // }



    if (this.state.toDashboard === true) {
      window.location.href = file_ten_path;
      return <Redirect to='/dashboard' />
    }
    const { hrm } = this.state;

    return (
      <React.Fragment>
        <div className="text-center mt-4">
        </div>
        <Card>
          <CardBody>
            <div className="m-sm-4">
              <div className="text-center">
                {/* <img src={image2} style={{'width':'40px','height':'40px'}}></img> */}
                {/* <img src={image2} style={{'width':'60px','height':'60px;' }} hspace={20}></img>
          <img src={image} style={{'width':'300px','height':'38px;'}}></img> */}
                <h3>Login</h3>
              </div><br /><br />
              <AvForm className="form" id="pform" onSubmit={this.onFormSubmit}>
                <AvGroup>
                  <Label for="example">{T("Email ID")}</Label>
                  <AvInput name="email" type="email" placeholder="Enter your email" value={hrm ? hrm.email : ''} />
                </AvGroup>
                <AvGroup>
                  <Label for="example">{T("Password")}</Label>
                  <AvInput name="password" type="password" placeholder="Enter your password" value={hrm ? hrm.password : ''} />
                </AvGroup>
                <small>
                  <Link to="/auth/reset-password">Forgot password?</Link>
                </small>
                <center> <div>
                  Don't have an account ? <a
                    //href="/signup" 

                    onClick={this.logine}
                  >{T(" Sign Up Now")}</a>
                </div></center>
                <div className="text-center mt-3">
                  <Link to="/dashboard">
                    <Button color="primary" type="submit" autofocus="autofocus" onClick={this.login}>
                      Sign in
                    </Button>
                  </Link>
                </div><br />
                <Row>
                </Row>
              </AvForm>
            </div>
          </CardBody>
        </Card>
        {/* <center><img src={image3} style={{'width':'35%'}}></img><br/>   
       <p><a href="https://www.epenh.com" target="_blank">Developed by Epenh Co. Ltd © 2019</a></p></center> */}
      </React.Fragment>
    )
  }
}

export default LogInForm;

