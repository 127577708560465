import * as types from "../constants";
import $ from 'jquery'; 
export function toggleSidebar() {
 
 // alert(types.SIDEBAR_VISIBILITY_SHOW);
 // alert("Hii");
 // $("form-control").css("width", "80%");
  // $("re_date1").css("background-color", "yellow");
  
  //alert($(window).width());


  if ($(".toggled")[0]){
    //document.querySelector('.form-control').style.width = "100%";re_date1_edob
    $(".form-control").css("width","100%");
    
    $(".css-2b097c-container").css("width","100%");
    
    $(".re_date1_edob").css("width","424px");
 //alert('1');
if ($(window).width()>1440) {
 
  $(".re_date11").css("width","512px");
  $(".re_date").css("width","791px");
  $(".re_date1").css("width","791px");

}
else 
{
  $(".re_date").css("width","448px");
  $(".re_date1").css("width","448px");
}
    


   // $(".form-control").css("min-width","100%");
    // Do something if class exists
} else {
    // Do something if class does not exist
    //document.querySelector('.form-control').style.width = "80%";
    $(".form-control").css("width","80%");
    $(".css-2b097c-container").css("width","80%");
    $(".re_date1_edob").css("width","448px");


    //alert('2');
    if ($(window).width()>1440) {
      // alert("in");
       $(".re_date11").css("width","485px");
       $(".re_date").css("width","743px");
       $(".re_date1").css("width","743px");
     }
     else
     {
       $(".re_date").css("width","448px");
       $(".re_date1").css("width","448px");
     }
         


//$(".form-control").css("min-width","80%");
}















  //
  return {
    type: types.SIDEBAR_VISIBILITY_TOGGLE
  };
 












  
}

export function logout() {
  var admin_id= localStorage.getItem('uname');
  // alert(localStorage);
  
  //  localStorage.clear();
    window.localStorage.clear();
    if(admin_id != null){
      window.location.href = '/';
    }

  //  this.props.history.push('/'+myConstClass.TENANT_PAGE_PATH);
 
}
export function navlink(id) {
  var admin_id= localStorage.getItem('uname');
  // alert(localStorage);
  
    localStorage.clear();
    // if(admin_id != null){
    //   window.location.href = '/';
    // }

  //  this.props.history.push('/'+myConstClass.TENANT_PAGE_PATH);
 
}

export function showSidebar() {


  
  return {
    type: types.SIDEBAR_VISIBILITY_SHOW
  };
}

export function hideSidebar() {
// alert('hii');
  return {
    type: types.SIDEBAR_VISIBILITY_HIDE
  };
}

export function enableRightSidebar() {
  return {
    type: types.SIDEBAR_RIGHT_ENABLE
  };
}

export function disableRightSidebar() {
  return {
    type: types.SIDEBAR_RIGHT_DISABLE
  };
}
