import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import * as myConstClass from '../../constant.js';
import moment from 'moment';
import {
  Breadcrumb,
  BreadcrumbItem, Row, Label,
  Card,
  CardBody,
  CardHeader,
  Container,
} from "reactstrap";
import {
  T,
  TF,
  Config
} from "react-translator-component";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import './common.scss';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {
  AvForm
} from "availity-reactstrap-validation";
const { SearchBar } = Search;
Config.default = localStorage.getItem("lang");
const tableColumns = [
  {
    dataField: "title",
    text: TF("{0}", T("Title")),
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "addressline1",
    text: TF("{0}", T("Address")),
    sort: true,
    headerStyle: { width: "20%" }
  },
  // {
  //   dataField: "city",
  //   text: "City",
  //   sort: true,
  //   headerStyle: { width:"10%" }
  // },
  // {
  //   dataField: "state",
  //   text: "State / Province ",
  //   sort: true,
  //   headerStyle: { width:"20%" } 
  // },
  // {
  //   dataField: "country",
  //   text: "Country",
  //   sort: true,
  //   headerStyle: { width:"10%" }
  // },
  // {
  //   dataField: "owner",
  //   text: "Owner",
  //   sort: true,
  //   headerStyle: { width:"10%" }

  // },
  // {
  //   dataField: "status",
  //   text: "Status",
  //   sort: true,
  //   headerStyle: { width:"10%" }

  // },
  {
    dataField: "action",
    text: TF("{0}", T("Action")),
    headerStyle: { width: "10%" },
    csvExport: false
  },

];



class Organization extends PureComponent {
  constructor(props) {
    super(props);
    this.routeChange = this.routeChange.bind(this);
    this.state = {
      user_id: 0,
      formData: [],
      posts: '',
      timeOut: 5000,
      showCloseButton: true,
      progressBar: true,
      position: "top-right"
    }
    //   submitted: false,
  }



  routeChange() {
    this.props.history.push('/');
  }




  componentDidMount() {
    document.querySelector('.react-bootstrap-table').style.minHeight = "243px";
    var admin_id = localStorage.getItem('uname');
    var login_type = localStorage.getItem('login_type');
    if (admin_id == null) {
      this.routeChange();
    }

    if (login_type == 'sub_admin') {
      var org_id = localStorage.getItem('organization_sub_admin');
      var dept_id = localStorage.getItem('department_sub_admin');
    }
    else {

      var org_id = 0;
      var dept_id = 0;
    }

    fetch(myConstClass.BASE_URL + 'organization/getOrganization_active/' + admin_id + '/0/' + org_id + '/' + dept_id)
      .then(response => response.json())
      .then(response => {
        let i = 1;
        response.forEach(element => {

          let purl = 'http://localhost:3000/career/' + element.org_code;
          // let prurl = 'http://localhost:3000/careercompany/' + element.org_code;
          // let purl = 'https://selfhost.workzoneplus.com/career/' + element.org_code;
          let prurl = 'https://selfhost.workzoneplus.com/careercompany/' + element.org_code;
          // let purl=myConstClass.CARREER_URL+element.org_code;
          // let prurl=myConstClass.CARREERCOMPANY_URL+element.org_code;
          element.action = <div>
            {/* <Icon.Edit3 style={{margin:'0px 15px 0px 0px',cursor:'Pointer'}} onClick={() =>this.edit(id)} />
      <Icon.Trash2 style={{cursor:'Pointer'}} onClick={() =>this.remove(id)}/> */}
            <a target="_blank" href={purl}  >Public Jobs</a>{' '}
            <a target="_blank" style={{ 'marginLeft': '40px' }} href={prurl}  >Private Jobs</a>
          </div>;
          // console.log(element);
          element.id = i;
          i++;
        });
        this.setState({ formData: response })
      }).catch(err => console.log(err))

    //for google translator


  }


  render() {
    return (
      <Container fluid>
        {/* <div style={{float:'right'}}  id="google_translate_element"></div><br/> */}
        <Header>
          <HeaderTitle>  {TF("{0} {1} {2}", T("Organization"), T("Job"), T("Url"))}</HeaderTitle>

          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/' + myConstClass.TENANT_PAGE_PATH + "dashboard"}>{T("Dashboard")}</Link>
            </BreadcrumbItem>
            {/* <BreadcrumbItem>
              <Link to="/dashboard">measures</Link>
            </BreadcrumbItem> */}
            <BreadcrumbItem active>{TF("{0} {1} {2}", T("Organization"), T("Job"), T("Url"))}</BreadcrumbItem>
            {/* <button class="mr-1 mb-1 btn btn-outline-secondary" onClick={() =>this.edit(this.state.user_id)} style={{color:'#ffffff',border: '1px solid #ffffff',float:'right !important',margin:'auto'}}><Icon.PlusCircle />&nbsp;&nbsp;ADD</button> */}

          </Breadcrumb>
          {/* <span >ADD</span> */}

        </Header>
        <Card>
          {/* <AvForm id="sform">
         <Row className="f_class_org">
         <div class="col-md-4"></div>
          <div class="col-md-4"></div>
              <div class="col-md-4">
                <AvGroup>
                  <Label for="example" className="search_org_label" >Search</Label>
                  <AvInput name="search" className="search_org" onChange={this.search} type="text" id="example"/>
                </AvGroup>
              </div>
            </Row>
           </AvForm><br/> */}
          <ToolkitProvider
            keyField="name"
            data={this.state.formData}
            columns={tableColumns}
            exportCSV={{
              fileName: TF("{0} {1} {3}_", T("Organization"), T("List"), T("Url")) + moment() + '.csv',
              exportAll: true
            }}
            search
          >
            {props => (
              <div>
                <CardHeader>

                </CardHeader>
                <CardBody>
                  <AvForm id="pform">
                    <Row>
                      <div class="col-md-4">
                        <Label for="example" className="search_title"></Label>


                        {/* <div class="float-right pull-right" style={{ left: '398px',top: '-21px'}}>
                      <CsvDownloader
                      className="csv"
                      style={{float:'right',backgroundColor:"#4B0082"}}
                      filename={fileName}
                        columns={csvColumns}
                        datas={csvData}
                      text="Export" /></div> */}

                      </div>
                      <div class="col-md-4"></div>
                      <div class="col-md-1"></div>
                      <div class="col-md-3" style={{ marginTop: '34px' }}>
                        <Label for="example">  </Label>
                        <SearchBar {...props.searchProps} placeholder={T("Search")} />
                      </div>
                      {/* <div class="col-md-4"></div>
                    <div class="col-md-4">
                      <AvGroup>
                        <Label for="example" className="search_title">Search</Label>
                        <AvInput name="search" className="search" onChange={this.search} type="text" id="example"/>
                      </AvGroup>
                    </div> */}
                    </Row>
                  </AvForm>

                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4
                    bordered={false}
                    pagination={paginationFactory({
                      // sizePerPage: 25,
                      sizePerPage: 10,
                      sizePerPageList: [5, 10, 25, 50]
                    })}
                  />
                </CardBody>
              </div>
            )}
          </ToolkitProvider>
        </Card>

        {/* <ExportCSVTable /> */}
      </Container>
    );
  }


}

export default Organization;