
module.exports = {
  "Yes": "是",
  "Male": "男",
  "Female": "女",
  "Other": "其他",
  "Non": "没有",
  "Spouse": "配偶",
  "Child": "孩子",
  "husband": "丈夫",
  "brother": "兄弟",
  "sister": "姐妹",
  "grandfather": "祖父",
  "grandmother": "祖母",
  "grandson": "孙子",
  "granddaughter": "孙女",
  "uncle": "伯父",
  "aunt": "伯母",
  "nephew": "ក外甥",
  "niece": "外甥女",
  "cousin": "表兄",
  "friend": "朋友",
  "Monthly": "每月",
  "Yearly": "每年",
  "Security": "安全",
  "and": "和",
  "Sub": "子",
  "Admin": "管理",
  "Comment": "评论",
  "Description": "描述",
  "See": "看",
  "Make": "做",
  "ON": "在",
  "Amount": "数额",
  "Added": "添加",
  "Successfully": "成功",
  "Updated": "更新",
  "Your": "你的",
  "form": "表单",
  "is": "正在",
  "submitted": "已提交",
  "Manage": "管理",
  "marked": "标记",
  "with": "跟",
  "are": "是的 ",
  "required": "必填",
  // "Monthly":"ប្រចាំខែ",
  "Hourly": "每小时",
  "Weekly": "每周",
  "OK": "行",
  "Complaint": "投诉",
  "From": "从",
  "Subject": "主题",
  "Category": "类别",
  "Number of Days Outstanding": "未完成天数",
  "Open": "打开",
  "Are you sure?": "你确定吗？",
  "that you want to delete this record?": "您要删除此记录吗？",
  "Deleted": "已删除",

  "View": "视图",
  "Against": "反对",
  "Uploaded": "已上传",
  "Taken": "采取",
  "Attendance": "考勤",
  "In-Time": "打卡-进",
  "Out-Time": "打卡-出",
  "Total": "总",
  "Work": "工作",
  "Time": "时间",
  "Break": "短休",
  "Are you sure that you want to edit this record which done by hr admin?": "您确定要编辑由人力资源管理员完成的此记录吗？",
  "Are you sure that you want to edit this record which done by employee login?": "您确定要编辑通过员工登录完成的此记录吗？",
  "Deleted Successfully": "成功删除",
  "Archived": "存档",
  "Check In Radius Check": "检入半径检查",
  "Check Out Radius Check": "检出半径检查",
  "Done By HR ADMIN": "由人力资源管理员完成",
  "Write reason of delete:": "写下删除原因：",
  //"Are you sure?":"តើអ្នកប្រាកដទេ?",
  "Are you sure that you want to delete this record?": "是否确实要删除此记录？",
  "ADD": "添加",
  "Hrs": "小时",
  "Min": "分钟",
  "Enter Rest Time as Hrs:Min": "输入休息时间作为小时：分钟",
  "Enter Early Leaving as Hrs:Min": "输入提前离开作为小时：分钟",
  "Please enter In time": "输入打卡时间 -进",
  "Please enter Out time": "输入打卡时间 -出",
  "Can not Add Attendance": "無法添加考勤",
  "YYYY-MM-DD": "ឆ្នាំ-ខែ-ថ្ងៃ",
  "All fields marked with * are required": "所有標有 * 的字段都是必需的",
  "Positions": "职位",
  "Departments": "部门",
  "Actual": "實際的",
  "Reason": "原因",
  "Can not Add Privilege": "无法添加权限",
  "Can not Edit Privilege": "无法编辑权限",
  "Can not Delete Privilege": "无法删除权限ធ",
  "Pending": "待办的",
  "Approved": "得到正式认可的",
  "Rejected": "拒绝",
  "Please": "请",
  //"Add":"បន្ថែម",
  "Check Your Leave Bank": "检查你的休假银行",
  "From Date should not be greater than To Date": "起始日期不应大于截止日期",
  //"Check Your Leave Bank":"ពិនិត្យសមតុល្យវត្តមានរបស់អ្នក",
  "Please add leave templete for this employee ": "请为该员工添加请假模板",
  "Leave present at this date": "在这个日期离开",
  "can": "能",
  "not": "不是",
  "Start": "开始",
  "End": "结尾",
  "Duration": "期间",
  "Starts in first half": "上半年开始",
  "Starts in second half": "下半场开始",
  "Full Day Leave": "全天假",
  "Post Edit Remark": "发表编辑备注",
  "Attachment": "依恋",
  "Staff": "职员",
  "Staffs": "工作人员",
  "Exists": "存在",
  "Not": "不是",
  "Found": "成立",
  "Any": "任何",
  "App": "应用程序",
  "URL": "网址",
  "Go to App Url": "转到应用程序网址",
  "Created At": "创建于",
  "Announcement": "公告",
  "Announcements": "公告",
  "Success": "成功",
  "Available": "可用的",
  "Model": "模型",
  "Asset": "资产",
  "Assigned": "已分配",
  "Issue On": "发行日期",
  "Sr": "锶",
  "Please Start the camera": "请启动相机",
  "Please add the image": "请添加图片",
  "Your last Checked out": "您上次签出",

  "Bulk Import Failed": "批量导入失败",
  "in": "在",
  "Sample": "样本",
  "CSV": "CSV文件",
  "File": "文件",
  "Upload": "上传",
  "You must set location allow": "您必须设置位置允许",
  "Check Inout": "入住退房",
  "Submit": "提交",
  "Retry": "重试",
  "You already Checked In": "您已经签到",
  "Check In": "报到",
  "Check Out": "查看",
  "Latitude": "纬度",
  "Longitude": "纬度",
  "List": "列表",
  "Employees": "雇员",
  "Dashboard": "ផ仪表板",
  "Select": "选择",
  "Organization": "组织",
  "Status": "地位",
  "Department": "部门",
  "Position": "位置",
  "Export": "出口",
  "Import": "进口",
  "Update": "更新",
  "Qrcode": "二维码",
  "Search": "搜索",
  "No": "不",
  "New": "新的",
  "Complaints": "投诉",
  "Information": "信息",
  "Employee": "员工",
  "Email": "电子邮件",
  "Mobile": "移动的",
  "Action": "行动",
  "Add": "添加",
  "Edit": "编辑",
  "Basic": "基本的",
  "Info": "信息",
  "Leaves": "树叶",
  "Points": "积分",
  "Documents": "文件",
  "Assets": "资产",
  "Salary": "薪水",
  "Note": "笔记",
  "Payslips": "工资单",
  "Emergency": "紧急情况",
  "Contacts": "联系人",
  "First": "第一的",
  "Name": "姓名",
  "ID": "ID",
  "Last": "最后的",
  "Middle": "中间",
  "Address": "地址",
  "Gender": "性别",
  "Date": "日期",
  "Of": "的",
  "Birth": "出生",
  "Timezone": "时区",
  "Location": "地点",
  "Long": "长的",
  "Lat": "纬度",
  "Restrict": "限制",
  "CheckIn": "报到",
  "Radius": "半径",
  "CheckOut": "查看",
  "Nationality": "国籍",
  "Resident": "居民",
  "Shift": "转移",
  "Group": "团体",
  "JD": "京东",
  "Title": "标题",
  "Access": "使用权",
  "UserType": "用户类型",
  "QR": "二维码",
  "Download": "下载",
  "Cancel": "取消",
  "Enter": "进入",
  "Password": "密码",
  "In-Active": "活动中",
  "Dependent": "依赖",
  "Relation": "关系",
  "Present": "展示",
  "Age": "年龄",
  "Save": "保存",
  "changes": "更改",
  "Leave": "离开",
  "Template": "模板",
  "Current": "当前的",
  "Balance": "平衡",
  "Allowed": "允许",
  "Negative": "消极的",
  "Transaction": "交易",
  "History": "历史",
  "Mannual": "手册",
  "Credit": "信用",
  "Debit": "借方",
  "Close": "关闭",
  "Frequency": "频率",
  "Quantity": "数量",
  "Paycode": "支付码",
  "Type": "类型",
  "Opening": "开幕式",
  "All": "全部",
  "Document": "文档",
  "Days": "天",
  "to": "到",
  "Already": "已经",
  "Expired": "已到期",
  "Expiry": "到期",
  "Remark": "评论",
  "Declaration": "宣言",
  "Issue": "问题",
  "Return": "返回",
  "Allowance": "津贴",
  "Code": "代码",
  "taxable": "应税",
  "tax": "税",
  "Telephone": "电话",
  "Distance": "距离",
  "Check out details are Not Available": "查看详细信息不可用",
  "Details": "细节",
  "Get": "得到",
  "Image": "图像",
  "This entry is generated by Admin": "此条目由管理员生成",
  "for": "为了",
  "CheckInOut": "签入签出",
  "enter": "进入",
  "Email ID": "电子邮件ID",
  "password": "密码",
  "Incorrect": "不正确",
  "Credentials": "证书",
  "Login": "登录",
  "Unsuccessful": "不成功",
  "Forgot": "忘记",
  "Sign Up Now": "立即注册",
  "Sign in": "登入",
  "Banner": "横幅",
  "Size": "尺寸",
  "Sequence": "顺序",
  "Publish": "发布",
  "Blog": "博客",
  "Blogs": "博客",
  "Draft": "草稿",
  "Unpublished": "未发表",
  "Published": "发布",
  "type": "类型",
  "Video": "视频",
  "Back to": "回到",
  "Chapters": "章节",
  // "Chapters":"ជំពូក",
  "Chapter": "章节",
  "Topics": "主题",
  "Under this": "在这之下",
  "Course": "课程",
  "PDF": "PDF",
  "VIDEO": "视频",
  "Topic": "话题",
  "Involved": "涉及",
  "By": "经过",
  "Welcome back": "欢迎回来",
  "Data": "数据",
  "For": "为了",
  "From Date": "从日期",
  "To Date": "迄今为止",
  "TODAY'S": "今天",
  "ATTENDANCE": "出席",
  "THIS": "这",
  "MONTH": "月",
  "LEAVE": "离开",
  "TOTAL": "全部的",
  "ORGANIZATIONS": "组织",
  "ACTIVE": "积极的",
  "EMPLOYEES": "雇员",
  "Today's": "今天",
  "Project Head": "项目负责人",
  "Contact No": "联系方式",
  "Add More": "添加更多",
  "Guest": "客人",
  "Visit To": "访问",
  "License Plate": "牌照",
  "Add Checkout Time": "添加结帐时间",
  "Business Visit": "商务考察",
  "Meeting": "会议",
  "Personal Visit": "个人访问",
  "Interview": "面试",
  "Presentation": "推介会",
  "Sales Visit": "销售拜访",
  "Client Visit": "客户拜访",
  "Supplier Visit": "供应商拜访",
  //"Other":"ផ្សេងៗ",
  "Take": "拿",
  "HR": "人力资源",
  "Email Already Exists": "电子邮件已经存在",
  "HR Code Already Exists": "HR代码已经存在",
  "Account Name Already Exists": "账户名已经存在",
  "Account Name should not be greater than 10 characters": " 账户名不能超过 10 个字符",
  "Database not found with given account name, Please create database first": "找不到具有给定帐户名的数据库，请先创建数据库",
  "Database not available to create HR admin": "数据库不可用于创建 HR 管理员",
  "Min.Amount": "最低金额",
  "Max.Amount": "最大金额",
  "Base amount": "基础金额",
  "Percentage": "百分比",
  "Tax": "税",
  "Dependence allowance ": "扶养津贴",
  "Tax Bracket List": "税级清单",
  "Tax Bracket": "税率",
  "Super User": "超级用户",
  "Day": "天",
  "Monday": "周一",
  "Rest-Time": "休息时间",
  "tuesday": "周二",
  "Wednesday": "周三",
  "Thursday": "周四",
  "Friday": "星期五",
  "Saturday": "周六",
  "Sunday": "星期日",
  "Signup": "报名",
  "Requests": "要求",
  "Reference": "参考",
  "Account": "帐户",
  "Personal": "个人的",
  "Create": "创造",
  "user Roles": "用户角色",
  "Task": "任务",
  "In Progress": "进行中",
  "Completed": "完全的",
  "Logs Present in this Task so cant be deleted": "此任务中存在日志，因此无法删除",
  "Log Text": "日志文本",
  "Projects": "项目",
  "Member": "成员",
  "Moderate": "缓和",
  "Low": "低的",
  "High": "高的",
  "Very High": "很高",
  "Priority": "优先事项",
  "Visit": "访问",
  "Weight": "重量",
  "Value": "价值",
  "Score": "分数",
  "Completion": "完成",
  "Uploaded image has not valid Height and Width": "រ上传的图片没有有效的高度和宽度",
  "Promotion": "晋升",
  "Redirect": "重定向",
  "Task Presnt in this Project so cant be deleted": "此项目中存在任务，因此无法删除",
  "Now": "现在",
  "Importance": "重要性",
  "Performance Kpi": "业绩关键绩效指标",
  "Please add the reason of delete": "请补充删除原因",
  "Role": "角色",
  "Please select at least one module": "请至少选择一个模块",
  "Analyst": "分析师",
  "analytics": "分析",
  "Top Selling": "畅销",
  "Shop": "店铺",
  "Cart": "购物车",
  "File size less than 1 MB": "文件大小小于 1 MB",
  "Check Discount Price": "查看折扣价",
  "Required": "必需的",
  "Investigating": "调查",
  "Incident": "事件",
  "Job": "工作",
  "Level": "等级",
  "Grade": "年级",
  "Attach": "附",
  "Functions": "功能",
  "Qualification": "资质",
  "Responsibilities": "职责",
  "Reporting": "报告",
  "Perks": "津贴",
  "Benefits": "好处",
  "Make-Model": "制作模型",
  "Check IN/OUT": "入住/退房",
  "Please Upload the File": "请上传文件",
  "No mid month": "没有月中",
  "Mid Month": "月中",
  "Payroll": "工资单”",
  "Process": "过程",
  "Bonus": "奖金",
  "Working": "在职的",
  "Test": "测试",
  "Live": "居住",
  "Line": "线",
  "Entry": "入口",
  "Currency": "货币",
  "Rate": "速度",

  "Banners": "横幅",
  "Orders": "产品",
  "Product": "支付代码",
  "Pay Codes": "支付代码",
  "Comments": "评论",
  "Career": "职业",
  "Profile": "轮廓",
  "Employee Presence": "员工现场",
  "Employee Absence": "员工缺勤",
  "My Job Description": "我的职位描述",
  "Job Description": "职位描述",
  "Settings": "设置",
  "Public Holidays": "公共假期",
  "Master Department": "硕士部",
  "Point Declaration": "积分宣言",
  "User Role": "用户角色",
  "Admin Apps": "管理应用程序",
  "Groups": "团体",
  "Employee Bulk Import": "员工批量导入",
  "Guests": "客人",
  "Check In Out": "入住退房",
  "Incident Report": "事件报告",
  "Tasks": "任务",
  "Work Plan": "工作计划",
  "My": "我的",
  "Promotions": "促销活动",
  "Payroll Line Entry": "工资单条目",
  "Employee Chat": "员工聊天",
  "Messages": "消息",
  "Training": "训练",
  "SignUp Requests": "注册请求",
  "My Profile": "我的簡歷",
  "Employee Career": "员工生涯",
  "All Job Post": "所有职位",
  "Application List": "申请清单",
  "Job Frontend Url": "工作前端网址",
  "Manage Shifts": "管理班次",
  "Shift Scheduler": "轮班调度员",
  "Shift Scheduler History": "排班历史",
  "Shift Schedule": "轮班时间表",
  "Constants": "常量",
  "No Preference": "没有偏好",
  "Fresher": "新鲜",
  "Year": "年",
  "Short Description": "简短的介绍",
  "Long Description": "详细描述",
  "Add Thumbnail Image": "添加缩略图",
  "Enter Valid link": "输入有效链接",
  "In Stock": "有存货",
  "Out Of Stock": "缺货",
  "In Active": "在活动中",
  "Ordered": "订购",
  "Accepted": "公认",
  "Rejected and Closed": "拒绝和关闭",
  "Shipped/InTransit": "已发货/在途",
  "Completed and Closed": "完成并关闭",
  "Cancelled and Closed": "取消和关闭",
  "Order No": "订单号",
  "Order On": "订购",
  "Product Feature": "产品特点",
  "Subtotal": "小计",
  "No items in the cart": "购物车中没有商品",
  "Place Order": "下订单",
  "Change": "改变",
  // "Select":"ជ្រើសរើស",
  "Language": "语言",

  ////////////////////
  "Job Title": "职称",
  "All Employee": "全体员工",
  "Day Leave": "请假",
  "System Logs": "系统日志",
  "Employee Documents": "员工文件",
  "Leave Types": "请假类型",
  "Allowance Type": "津贴类型",
  "Allowance Code": "津贴代码",
  "Note Code": "注码",
  "Document Code": "文档代码",
  "Comment Category": "评论分类",
  "Comment Type": "评论类型",


  "My Shift Schedule": "我的轮班时间表",
  "My Orders": "我的订单",
  "Organization Blogs": "组织博客",
  "My Attendance": "我的出席",
  "My Leaves": "我的叶子",
  "Acess Code": "访问代码",
  "My Points": "我的积分",
  "My Documents": "我的文件",
  "My Assests": "我的资产",
  "My Payslips": "我的工资单",
  "Sub Admin": "子管理员",
  "Manage Tax Brackets": "管理税级",
  "All Shifts": "所有转变",
  "Shift Scheduler Import": "班次调度程序导入",
  "Project Task": "项目任务",
  "Employee Training": "员工培训",
  "Employee Shop": "员工商店",
  "Product Category": "产品分类",
  "Products": "产品",
  "Public Holiday": "公众假期",
  "Blog Type": "博客类型",
  "Job Type": "工作类型",
  "Course Type": "课程类型",
  "Annoucement Type": "公告类型",


  "Add/Subtract": "加/减",
  "Mood": "情绪",
  "Negative Balance": "负余额",
  "Leave Bank Report": "离开银行报告",
  "Payroll Type": "薪资类型",
  "Flat Salary": "固定工资",
  "Salary Attendance": "工资考勤",
  "Salary Attendance + OT": "工资出勤+加班",
  "Hourly Wages": "时薪",
  "Deduction": "扣除",
  "Ajustment": "调整",
  "Settlements": "定居点",
  "Current Balance": "当前余额",
  "Allowed Negative Balance": "允许负余额",
  "No Record Found": "没有找到记录",
  //"Work Schedular":"Work Schedular",



  ////////Date 23/2/2kl
  "Upcoming": "即将到来",
  //"Today's":"Today's",
  "Absent": "缺席的",
  "Apply": "申請",
  "Database Host": "数据库主机",
  "Database User": "数据库用户",
  "Database Password": "数据库密码",
  "Primary Database Name": "主数据库名称",
  "Payroll Database Name": "工资数据库名称",
  "Language Transalation Type": "语言翻译类",
  "Google Transaltor": "谷歌交易商",
  "Custom": "风俗",
  "Default Language": "默认语言",
  "English": "英语",
  "Khemer": "高棉语",
  "Chinese": "中国人",
  "More than 1 organization cannot be added": "不能添加超过 1 个组织",
  "Organization Code already exists": "组织代码已存在",
  "Add Payslip Header or Footer or organization logo": "添加工资单页眉或页脚或组织徽标",
  "Company Policy": "公司政策",
  "Employee Handbook": "员工手册",
  "Payslip Header": "工资单标题",
  "Payslip Footer": "工资单页脚",
  "Color Code": "色标",
  // "Sequence":"Sequence",

  /****29 march */

  'Shifts': '轉移',
  "Visitor": "遊客",
  "Work Scheduler": "工作調度器",
  "Work Scheduler History": "工作調度歷史",
  "Work Scheduler Import": "工作調度程序導入",
  "Vehicle": "車輛",
  "Total IN Visitor": "IN 訪客總數",



  /////////Date 28/4/23/////////
  "Seniority Pay": "工齡工資",
  "Convert to USD Dollar": "轉換為 美元",
  //"":"",
  //'Absent':'Absent',




  // //"Documents":"Documents",
  // "Expired":"Expired",
  // "in":"in",
  // "days":"days",
  // "New":"New",
  // "Complaints":"Complaints",
  // "No":"No",
  // "Messages":"Messages",
  // "Arrived":"Arrived",
  // "Leaves":"Leaves",
  // "today":"today",
  // "My Profile":"My Profile",
  // "Sign out":"Sign out",
  // "Flat Salary":"Flat Salary",
  // "Salary Attendance":"Salary Attendance",
  // "OT":"OT",
  // "Hourly Wages":"Hourly Wages",
  // "NOTE:In Time and Out Time in 24 Hrs Format":"NOTE:In Time and Out Time in 24 Hrs Format",
  // "Please select a date":"Please select a date",
  // "Rest":"Rest",
  // "Early Leaving":"Early Leaving",
  // "Over Time":"Over Time",
  // "Time Late":"Time Late",
  // "Previous":"Previous",
  // "Records":"Records",
  // "Check IN Out Records":"Check IN Out Records",
  // "Check IN":"Check IN",
  // "Select Status":"Select Status",
  // "ADD":"ADD",
  // "Export":"Export",
  // "Active":"Active",
  // "Title":"Title",
  // "Address":"Address",
  // "Owner":"Owner",
  // "Status":"Status",
  // "Action":"Action",
  // "Photo":"Photo",
  // "Select":"Select",
  // "User":"User",
  // "Employee Local Name":"Employee Local Name",
  // "Vehicle":"Vehicle",
  // "Notice Pay":"Notice Pay",
  // "See":"See",
  // "Not found":"Not found",
  // "Upcoming":"Upcoming",
  // "Absent":"Absent",
  // "Expire With in Days":"Expire With in Days",
  // "Leave Bank":"Leave Bank",
  // "To":"To",
  // "Outstanding":"Outstanding",
  // "Mood":"Mood",
  // "No options":"No options",
  // "General":"General",
  // "Very Sad":"Very Sad",
  // "Sad":"Sad",
  // "Just OK":"Just OK",
  // "Good":"Good",
  // "Fantastic":"Fantastic",
  // "Document Type List":"Document Type List",
  // "Dashboard":"Dashboard",
  // "Doc Type":"Doc Type",
  // "All":"All",
  // "Expiry By":"Expiry By",
  // "Days to expire";"Days to expire",
  // "All Expiry Type":"All Expiry Type",
  // "Days to expire":"Days to expire",
  // "Already Expired":"Already Expired",
  // "All Document Type":"All Document Type",
  // "In-Active":"In-Active",
  // "Select Department":"Select Department",
  // "Select Employee":"Select Employee",
  // "Work Schedular":"Work Schedular",
  // "Work Schedular History":"Work Schedular History",
  // "Work Schedular Import":"Work Schedular Import",
  // "Calender View":"Calender View",
  // "Choose File":"Choose File",
  // "No file chosen":"No file chosen",
  // "Download Sample File":"Download Sample File",
  // "Download Sample CSV File":"Download Sample CSV File",
  // "Upload":"Upload",
  // "Preview Of Shift Scheduler Table":"Preview Of Shift Scheduler Table",
  // "Shift Schduler of Parent Table":"Shift Schduler of Parent Table",
  // "All Organization":"All Organization",
  // "Test Payroll":"Test Payroll",
  // "Rate":"Rate",
  // "View":"View",
  // "Payslip":"Payslip",
  // "All Payroll Entry":"All Payroll Entry",
  // "Payroll Title":"Payroll Title",
  // "Employee ID":"Employee ID",
  // "Employee Name":"Employee Name",
  // "Department":"Department",
  // "Designation":"Designation",
  // "Color Code":"Color Code",
  // "Multiplier":"Multiplier",
  // "Pay Code":"Pay Code",
  // "Taxcode":"Taxcode",
  // "Project":"Project",
  // "In Progress":"In Progress",
  // "Closed":"Closed",
  // "Project ID":"Project ID",
  // "Status":"Status",
  // "Open":"Open",
  // "Id":"Id",
  // "Job Title":"Job Title",
  // "Job ID":"Job ID",
  // "Description":"Description",
  // "Close Date":"Close Date",
  // "No Of Candidates Applied":"No Of Candidates Applied",
  // "Number Of":"Number Of",
  // "Url":"Url",
  // "Normal":"Normal",
  // "Document Uploaded":"Document Uploaded",
  // "Public Jobs":"Public Jobs",
  // "Private Jobs":"Private Jobs",
  // "Course List":"Course List",
  // "Course":"Course",
  // "Select Course Type":"Select Course Type",
  // "Departments":"Departments",
  // "Description":"Description",
  // "Cancel":"Cancel",
  // "Chat":"Chat",
  // "Select Department":"Select Department",
  // "Select Organization":"Select Organization",
  // "Load All":"Load All",
  // "SKU":"SKU",
  // "Cost":"Cost",
  // "Price":"Price",
  // "Discount Price":"Discount Price",
  // "Link":"Link",
  // "Brand":"Brand",
  // "Top List":"Top List",
  // "Image Size":"Image Size",
  // "Documents Uploaded":"Documents Uploaded",
  // "Select Status":"Select Status",
  // "No":"No",
  // "View resume":"View resume",
  // "Applied For":"Applied For",
  // "Applicant":"Applicant",
  // "Application Name":"Application Name",
  // "Applicant Mobile":"Applicant Mobile",
  // "Applicant Email":"Applicant Email",
  // "Status":"Status",
  // "Remark":"Remark",
  // "Thumbnail":"Thumbnail",
  // "Business Promotion":"Business Promotion",
  // "Visitor":"Visitor",
  // "Total IN Visitor":"Total IN Visitor",
  // "QR Code":"QR Code",
  // "Phone":"Phone",
  // "Send QR Code":"Send QR Code",
  // "Not Checked Out":"Not Checked Out",
  // "History":"History",
  // "Open":"Open",
  // "Reported":"Reported",
  // "Created On":"Created On",
  // "Incident From":"Incident From",
  // "Incident Title":"Incident Title",
  // "Incident Form":"Incident Form",
  // "Level":"Level",
  // "Remark":"Remark",
  // "Status":"Status",
  // "Description of incident":"Description of incident",
  // "Check In":"Check In",
  // "code":"code",
  // "Address Line 1":"Address Line 1",
  // "City":"City",
  // "State/Province":"State/Province",
  // "Country":"Country",
  // "Attendance Radius (In Meters)":"Attendance Radius (In Meters)",
  // "Company Policy":"Company Policy",
  // "Employee Handbook":"Employee Handbook",
  // "Payslip Header":"Payslip Header",
  // "Payslip Footer":"Payslip Footer",
  // "Height":"Height",
  // "Width":"Width",
  // "ID Template":"ID Template",
  // "Select Organization Logo":"Select Organization Logo",
  // "Public Holiday":"Public Holiday",
  // "Holiday":"Holiday",
  // "Apply Shift Schduler":"Apply Shift Schduler",
  // "Pay":"Pay",
  // "Name":"Name",
  // "Shifts":"Shifts",
  // "Pay":"Pay",
  // "Negative Balance":"Negative Balance",
  // "This field is invalid":"This field is invalid",
  // "Leave Type":"Leave Type",
  // "Admin app":"Admin app",
  // "URL":"URL",
  // "Asset":"Asset",
  // "Allowance Code":"Allowance Code",
  // "Description":"Description",
  // "Cancel":"Cancel",
  // "Add":"Add",
  // "Document Type":"Document Type",
  // "Employee Group":"Employee Group",
  // "Job ID":"Job ID",
  // "Annoucement":"Annoucement",
  // "Course Type":"Course Type",
  // "System Logs":"System Logs",
  // "User":"User",
  // "User IP":"User IP",
  // "Location":"Location",
  // "Operation":"Operation",
  // "Affected Table":"Affected Table",
  // "Rows":"Rows",
  // "Timestamp":"Timestamp",

































  //Manage Tax Brackets             ,  
};
// }
// else
// {



// module.exports = {
//   "Employees List":
//     "Employees List",
//     "Dashboard":
//     "Dashboard",
//     "Select Organization":
//     "Select Organization",
//     "Status":
//     "Status",
//     "Select Department":
//     "Select Department",
//     "Select Positions":
//     "Select Positions",
//     "Export":
//     "Export",
//     "Import +":
//     "Import +",
//     "Update Qrcode":
//     "Update Qrcode",
//     "Search":
//     "Search",
// };

// }


