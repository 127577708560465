import React ,{ PureComponent } from "react";
import { Link } from "react-router-dom";
import * as myConstClass from '../../constant.js';
import { Button } from "reactstrap";

class Page406 extends PureComponent {
  constructor(props) {
    super(props);
    this.routeChange = this.routeChange.bind(this);

}
routeChange() {
  this.props.history.push('/');
}
componentDidMount(){
 // localStorage.clear();
  
}

render() {

  return (
  
    <div className="text-center">
    {/* <h1 className="display-1 font-weight-bold">404</h1>
     <p className="h1">Page not found.</p> */}
    <p className="h2 font-weight-normal mt-3 mb-4">
     Connecting............
    </p> 
     <Link to={'/'+myConstClass.TENANT_PAGE_PATH+"guests/listing"}>
      <Button color="primary" size="lg">
       Return to Guest Listing and Refresh It
      </Button>
    </Link>
  </div>
  )
}
}


// const Page404 = () => (

//   <div className="text-center">
//     {/* <h1 className="display-1 font-weight-bold">404</h1>
//      <p className="h1">Page not found.</p> */}
//     <p className="h2 font-weight-normal mt-3 mb-4">
//      Connecting............
//     </p> 
//      <Link to={'/'+myConstClass.TENANT_PAGE_PATH+"dashboard"}>
//       <Button color="primary" size="lg">
//        Return to Dashboard
//       </Button>
//     </Link>
//   </div>
// );

export default Page406;
