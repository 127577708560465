import React from "react";
import { connect } from "react-redux";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";
import {
  Translator,
  T,
  TF,
  LanguageList,
  Config
} from "react-translator-component";
Config.list = {
  KH: {
    text: "Khmer",
    icon: "../flags/de.svg",
    file: require("../locale/KH.js")
  },
  en: {
    text: "English",
    icon: "../flags/en.svg",
   file: require("../locale/en.js")
    //file:require(en)
  },
  ch: {
    text: "Chinese",
    icon: "../flags/en.svg",
    file: require("../locale/ch.js")
    
  },
};
Config.default = localStorage.getItem("lang");
//alert('in dashboard layout');
//localStorage.setItem('66', TF("{0}", T("Check In Out")));


///alert(TF("{0}", T("Check In Out")));

const Dashboard = ({ sidebar, children }) => (
 
  <React.Fragment>
     <Translator>
    <Wrapper>
      {!sidebar.isOnRight && <Sidebar />}
      <Main>
        <Navbar />
        <Content>{children}</Content>
        <Footer />
      </Main>
      {sidebar.isOnRight && <Sidebar />}
    </Wrapper>
    </Translator>
  </React.Fragment>
);

export default connect(store => ({
  sidebar: store.sidebar
}))(Dashboard);
