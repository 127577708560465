var edit_msg= localStorage.getItem('edit_msg');
  module.exports = {
  "List":"List",
  "Employees":"Employees",
  "Dashboard":"Dashboard",
  "Select":"Select",
    "Organization":"Organization",
    "Status":"Status",
     "Department":"Department",
    "Position":"Position",
    "Export":"Export",
    "Import":"Import",
    "Update":"Update",
    "Qrcode":"Qrcode",
    "Search":"Search",
    "No":"No",
    "New":"New",
    "Complaints":"Complaints",
    "Information":"Information",
    "Employee":"Employee",
    "Email":"Email",
    "Mobile":"Mobile",
    "Action":"Action",
    "ADD":"ADD",
    "Edit":"Edit",
    
   
};