import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Badge, Collapse } from "reactstrap";
import * as myConstClass from '../constant.js';
import IosPulse from "react-ionicons/lib/IosPulse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from 'jquery'; 
import routes from "../routes/index";
import './logo.scss';
import {
  Translator,
  T,
  TF,
  LanguageList,
  Config
} from "react-translator-component";


// import avatar from "../assets/img/avatars/avatar.jpg";
// const avatar = `http://localhost/workzone/backend/img/profile.png`; 
// const logo = `http://localhost/workzone/backend/img/clock_new.png`;
// const zone_logo = `http://localhost/workzone/backend/img/Work-Zone-Plus-logo.png`;

// const avatar = `https://alpha.workzoneplus.com/backend/img/profile.png`; 
// const logo = `https://alpha.workzoneplus.com/backend/img/clock_new.png`;
// const zone_logo = `https://alpha.workzoneplus.com/backend/img/Work-Zone-Plus-logo.png`;

const avatar = `https://beta.workzoneplus.com/backend/img/profile.png`; 
const logo = `https://beta.workzoneplus.com/backend/img/clock_new.png`;
const zone_logo = `https://beta.workzoneplus.com/backend/img/Work-Zone-Plus-logo.png`;

//localStorage.clear("lang");
Config.default = localStorage.getItem("lang");
Config.list = {
  default: localStorage.getItem("lang"),
  KH: {
    text: "Khemer",
    file: require("../locale/KH.js")
  },
  en: {
    text: "English",
    file: require("../locale/en.js")
    
  },
   ch: {
    text: "Chinese",
    file: require("../locale/ch.js")
    
  },
};

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon,
    isOpen,
    children,
    onClick,
    location,
    to
  }) => {
    const getSidebarItemClass = path => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/")
        ? "active"
        : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <span
          data-toggle="collapse"
          className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          <FontAwesomeIcon
            icon={icon}
            fixedWidth
            className="align-middle mr-2"
          />{" "}
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} pill className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={"sidebar-dropdown list-unstyled"}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon, location, to }) => {
    const getSidebarItemClass = path => {
      return location.pathname === path ? "active" : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <NavLink to={to} className="sidebar-link" activeClassName="active">
          {icon ? (
            <React.Fragment>
              <FontAwesomeIcon
                icon={icon}
                fixedWidth
                className="align-middle mr-2"
              />{" "}
              <span className="align-middle">{name}</span>
            </React.Fragment>
          ) : (
            name
          )}{" "}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} pill className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.state = {
      empData:[],
      user_id:'',
    };
  }

  toggle = index => {
    // Collapse all elements


    //alert('hiii');
    var login_id= localStorage.getItem('login_id');
    var login_type= localStorage.getItem('login_type');
      var admin_id= localStorage.getItem('uname'); 
    var pro_package= localStorage.getItem('package');
    var is_super_user= localStorage.getItem('is_super_user');
    this.state.pro_package = pro_package;

var pro_zone_logo_logo;
    if(pro_package == '1')
    {
      pro_zone_logo_logo= myConstClass.SITE_URL+`img/Work-Zone-Pro-logo11.png`;
      $("#pro_logo").attr("src",pro_zone_logo_logo);
    }
    else
    {
      pro_zone_logo_logo= myConstClass.SITE_URL+`img/Work-Zone-Plus-logo.png`;
      $("#pro_logo").attr("src",pro_zone_logo_logo);
    }






    













    // alert(pro_package);
      if(login_type == 'hr_admin' && is_super_user=='0' || login_type == 'super_admin'){
      fetch(myConstClass.BASE_URL+'user/getProfileInfo/'+login_id)
      .then(response => response.json())
      .then(response =>{console.log(response);this.setState({empData: response});})
      //  alert(empData);
      .catch(err => console.log(err))
    }
    else if(login_type == 'sub_admin' && is_super_user!='0'){
      fetch(myConstClass.BASE_URL+'user/getSuperAdminStaffInfo/'+is_super_user)
      .then(response => response.json())
      .then(response =>{console.log(response);this.setState({empData: response});})
      //  alert(empData);
      .catch(err => console.log(err))
    }


    else{
      fetch(myConstClass.BASE_URL+'user/getUserInfoE/'+login_id)
      .then(response => response.json())
      .then(response =>{console.log(response);this.setState({empData: response});})
      //  alert(empData);
      .catch(err => console.log(err))
  
    
    }






    // if(pathName==ten_path+'/dashboard')
    // {
    //  const isActive = false;
    // // const isOpen = route.open;
    // // const isHome = route.containsHome && pathName === "/" ? true : false;
    // /// alert(isActive);
    //  this.setState(() => ({
    //    [index]: isActive //|| isOpen  // || isHome
    //  }));
    // }
    // let ten_path;
    // var url = window.location.pathname;
 
    // var str = url.split("/");
    // ten_path = '/'+str[1]+'/'+ str[2]; 
    // //alert(ten_path+'/guests');
    //  /* Open collapse element that matches current url */
    //  const pathName = this.props.location.pathname;



    // if(pathName==ten_path+'/dashboard')
    // {
    //  alert('hii');
    //  const isActive = false;
    // // const isOpen = route.open;
    // // const isHome = route.containsHome && pathName === "/" ? true : false;
    // /// alert(isActive);
    //  this.setState(() => ({
    //    [index]: isActive //|| isOpen  // || isHome
    //  }));
    // }

    Object.keys(this.state).forEach(
      item =>
        this.state[index] ||
        this.setState(() => ({
          [item]: false
        }))
    );

    // Toggle selected element
    this.setState(state => ({
      [index]: !state[index]
    }));
  };

  componentWillMount() {
    var pro_package= localStorage.getItem('package');
    this.state.pro_package = pro_package;
    //var pro_package= localStorage.getItem('package');
    // var is_super_user= localStorage.getItem('is_super_user');
    // this.state.pro_package = pro_package;
//alert(TF("{0}",T("Language")));























var pro_zone_logo_logo;
    if(pro_package == '1')
    {
      pro_zone_logo_logo= myConstClass.SITE_URL+`img/Work-Zone-Pro-logo11.png`;
      $("#pro_logo").attr("src",pro_zone_logo_logo);
    }
    else
    {
      pro_zone_logo_logo= myConstClass.SITE_URL+`img/Work-Zone-Plus-logo.png`;
      $("#pro_logo").attr("src",pro_zone_logo_logo);
    }
   // alert('hii');
   let ten_path;
   var url = window.location.pathname;

   var str = url.split("/");
   ten_path = '/'+str[1]+'/'+ str[2]; 
   //alert(ten_path+'/guests');
    /* Open collapse element that matches current url */
    const pathName = this.props.location.pathname;
   // alert(pathName);
   
    routes.forEach((route, index) => {
    //  route.chidren;

   //  alert(route);
    //    alert(route.open);

let isActive;





route.name=T(route.name);









     if(route.path==ten_path+'/guests')
     {
      for(let i=0;i<route.children.length;i++)
      {
        var child=route.children[i];
      child.name=T(child.name);

      }
     if(pathName.indexOf("guests") > -1 || pathName.indexOf("incident") > -1)
         //if(pathName==ten_path+'/guests/*' || pathName==ten_path+'/incident/*')
         {
           
          //alert('hii1');
          //const isActive = pathName.indexOf(route.path) === 0;
          const isActive = true;
          const isOpen = route.open;
          const isHome = route.containsHome && pathName === "/" ? true : false;
         /// alert(isActive);
          this.setState(() => ({
            [index]: isActive || isOpen  // || isHome
          }));
         }
     }


     if(route.path==ten_path+'/employees')
     {
      
      for(let i=0;i<route.children.length;i++)
      {
        var child=route.children[i];
      child.name=T(child.name);

      }
      //route.chidren.name=TF("{0}",T(route.chidren.name));
      // alert('hii');
     // alert(pathName.indexOf("employee/"));

      // if(pathName.indexOf("employee") > -1)
      //   {
      //     alert(pathName.indexOf("employee/"));
      //   }
        //  if(pathName==ten_path+'/leave_type/listing' || pathName==ten_path+'/leaves/listing')
         if(pathName.indexOf("employee/") > -1  || pathName.indexOf("complaints") > -1
        || pathName.indexOf("attendance") > -1 || pathName.indexOf("absent") > -1 || pathName.indexOf("leaves") > -1
        || pathName.indexOf("admin_staff") > -1 
        )
         {
           
          //alert('hii1');
          //const isActive = pathName.indexOf(route.path) === 0;
          const isActive = true;
          const isOpen = route.open;
          //const isHome = route.containsHome && pathName === "/" ? true : false;
         /// alert(isActive);
          this.setState(() => ({
            [index]: isActive || isOpen //|| isHome
          }));
         }
     }
     //alert(route.path);

    //  if(route.path==ten_path+'/employees')
    //  {
    //   // alert('hii');
    //      if(pathName==ten_path+'/employee/listing' || pathName==ten_path+'/employee_groups/listing' || pathName==ten_path+'/complaints/listing')
    //      {
           
    //       //alert('hii1');
    //       //const isActive = pathName.indexOf(route.path) === 0;
    //       const isActive = true;
    //       const isOpen = route.open;
    //      // const isHome = route.containsHome && pathName === "/" ? true : false;
    //      /// alert(isActive);
    //       this.setState(() => ({
    //         [index]: isActive || isOpen //|| isHome
    //       }));
    //      }
    //  }

    //  if(route.path==ten_path+'/admin_staff')
    //  {
    //  //  alert(ten_path+'/guests');
    //      if(pathName==ten_path+'/admin_staff/listing' || pathName==ten_path+'/admin_staff/listingsecurity')
    //      {
           
    //       //alert('hii1');
    //       //const isActive = pathName.indexOf(route.path) === 0;
    //       const isActive = true;
    //       const isOpen = route.open;
    //      // const isHome = route.containsHome && pathName === "/" ? true : false;
    //      /// alert(isActive);
    //       this.setState(() => ({
    //         [index]: isActive || isOpen  // || isHome
    //       }));
    //      }
    //  }


     if(route.path==ten_path+'/settings')
     {
      // alert('hii');
      for(let i=0;i<route.children.length;i++)
      {
        var child=route.children[i];
      child.name=T(child.name);

      }

      if(pathName.indexOf("organization") > -1 || pathName.indexOf("department") > -1 || pathName.indexOf("holidays") > -1
      || pathName.indexOf("points") > -1 || pathName.indexOf("job_descripation") > -1 || pathName.indexOf("saroles") > -1
      || pathName.indexOf("leave_type") > -1 || pathName.indexOf("adminapp") > -1 || pathName.indexOf("assets") > -1
      || pathName.indexOf("allownce_type") > -1 || pathName.indexOf("allownce_code") > -1 || pathName.indexOf("note_code") > -1
      || pathName.indexOf("document_type") > -1 || pathName.indexOf("employee_groups") > -1
      )

        //   if(pathName==ten_path+'/organization/listing' || pathName==ten_path+'/department/listing' || pathName==ten_path+'/holidays/listing'
        //  || pathName==ten_path+'/points/listing'|| pathName==ten_path+'/job_descripation/listing' || pathName==ten_path+'/saroles/listing'
        //  )
         {
           
          //alert('hii1');
          //const isActive = pathName.indexOf(route.path) === 0;
          const isActive = true;
          const isOpen = route.open;
          const isHome = route.containsHome && pathName === "/" ? true : false;
         /// alert(isActive);
          this.setState(() => ({
            [index]: isActive || isOpen //|| isHome
          }));
         }
     }




     if(route.path==ten_path+'/payroll')
     {
      // alert('hii');
      for(let i=0;i<route.children.length;i++)
      {
        var child=route.children[i];
      child.name=T(child.name);

      }
      if(pathName.indexOf("tax_brackets") > -1 || pathName.indexOf("payroll_line_entry") > -1 || pathName.indexOf("payslip") > -1
      || pathName.indexOf("paycode") > -1 
      )

        //   if(pathName==ten_path+'/organization/listing' || pathName==ten_path+'/department/listing' || pathName==ten_path+'/holidays/listing'
        //  || pathName==ten_path+'/points/listing'|| pathName==ten_path+'/job_descripation/listing' || pathName==ten_path+'/saroles/listing'
        //  )
         {
           
          //alert('hii1');
          //const isActive = pathName.indexOf(route.path) === 0;
          const isActive = true;
          const isOpen = route.open;
          const isHome = route.containsHome && pathName === "/" ? true : false;
         /// alert(isActive);
          this.setState(() => ({
            [index]: isActive || isOpen //|| isHome
          }));
         }
     }


     if(route.path==ten_path+'/career')
     {
      for(let i=0;i<route.children.length;i++)
      {
        var child=route.children[i];
      child.name=T(child.name);

      }
      if(pathName.indexOf("jobpost") > -1 || pathName.indexOf("careers") > -1
      )

        //   if(pathName==ten_path+'/organization/listing' || pathName==ten_path+'/department/listing' || pathName==ten_path+'/holidays/listing'
        //  || pathName==ten_path+'/points/listing'|| pathName==ten_path+'/job_descripation/listing' || pathName==ten_path+'/saroles/listing'
        //  )
         {
           
          //alert('hii1');
          //const isActive = pathName.indexOf(route.path) === 0;
          const isActive = true;
          const isOpen = route.open;
          const isHome = route.containsHome && pathName === "/" ? true : false;
         /// alert(isActive);
          this.setState(() => ({
            [index]: isActive || isOpen //|| isHome
          }));
         }
     }
















     if(route.path=='/super_admin/index.php/settings')
     {
      for(let i=0;i<route.children.length;i++)
      {
        var child=route.children[i];
      child.name=T(child.name);

      }
          if(pathName=='/super_admin/index.php/organization/listing'
         )
         {
           
          //alert('hii1');
          //const isActive = pathName.indexOf(route.path) === 0;
          const isActive = true;
          const isOpen = route.open;
          const isHome = route.containsHome && pathName === "/" ? true : false;
         /// alert(isActive);
          this.setState(() => ({
            [index]: isActive || isOpen //|| isHome
          }));
         }
     }
      if(route.path==ten_path+'/shops')
     {
      for(let i=0;i<route.children.length;i++)
      {
        var child=route.children[i];
      child.name=T(child.name);

      }
        if(pathName.indexOf("productcategory") > -1 || pathName.indexOf("product") > -1 || pathName.indexOf("order") > -1
        || pathName.indexOf("banner") > -1 
        )
         {
           
          //alert('hii1');
          //const isActive = pathName.indexOf(route.path) === 0;
          const isActive = true;
          const isOpen = route.open;
          const isHome = route.containsHome && pathName === "/" ? true : false;
         /// alert(isActive);
          this.setState(() => ({
            [index]: isActive || isOpen //|| isHome
          }));
         }
     }

     if(route.path==ten_path+'/course')
     {
      for(let i=0;i<route.children.length;i++)
      {
        var child=route.children[i];
      child.name=T(child.name);

      }
        if(pathName.indexOf("course") > -1 || pathName.indexOf("chaptors") > -1
        )
         {
           
          //alert('hii1');
          //const isActive = pathName.indexOf(route.path) === 0;
          const isActive = true;
          const isOpen = route.open;
          const isHome = route.containsHome && pathName === "/" ? true : false;
         /// alert(isActive);
          this.setState(() => ({
            [index]: isActive || isOpen //|| isHome
          }));
         }
     }


     if(route.path==ten_path+'/projects')
     {
      for(let i=0;i<route.children.length;i++)
      {
        var child=route.children[i];
      child.name=T(child.name);

      }
          if(pathName.indexOf("projects") > -1 || pathName.indexOf("projecttasks") > -1
        )
         {
           
          //alert('hii1');
          //const isActive = pathName.indexOf(route.path) === 0;
          const isActive = true;
          const isOpen = route.open;
          const isHome = route.containsHome && pathName === "/" ? true : false;
         /// alert(isActive);
          this.setState(() => ({
            [index]: isActive || isOpen || isHome
          }));
         }
     }


     if(route.path==ten_path+'/shifts')
     {
      for(let i=0;i<route.children.length;i++)
      {
        var child=route.children[i];
      child.name=T(child.name);

      } if(pathName.indexOf("shifts") > -1 || pathName.indexOf("shift_schedular") > -1
      )
          
      // if(pathName==ten_path+'/shifts/listing' || pathName==ten_path+'/shift_schedular/listing' || pathName==ten_path+'/shift_schedular/Importschedular'
      //    )
         {
           
        //  alert('hii1');
          //const isActive = pathName.indexOf(route.path) === 0;
          const isActive = true;
          const isOpen = route.open;
          const isHome = route.containsHome && pathName === "/" ? true : false;
         /// alert(isActive);
          this.setState(() => ({
            [index]: isActive || isOpen || isHome
          }));
         }
     }















    });





    //  const isActive = pathName.indexOf(route.path) === 0;
     
  }

  componentDidMount(){


  // alert("in sidebar");


    //var name=TF("{0}",T("Dashboard"));
 


    localStorage.setItem('8', TF("{0}", T("My Profile")));
    
    localStorage.setItem('Dashboard', TF("{0}",T("Dashboard")));
localStorage.setItem('2', TF("{0}",T("Job Frontend Url")));

// localStorage.setItem('3', T("Career"));
localStorage.setItem('4', TF("{0}",T("Career")));
localStorage.setItem('5', TF("{0} {1}", T("Guests") , T("List")));
localStorage.setItem('6', TF("{0} {1}",T("Employee") , T("List")));
// localStorage.setItem('7', TF("{0}", T("My Profile")));

localStorage.setItem('9', TF("{0}", T("Signup Requests")));
localStorage.setItem('10', TF("{0}", T("System Logs")));
localStorage.setItem('11', TF("{0} {1}", T("Update") , T("Profile")));
localStorage.setItem('12', TF("{0} {1}", T("All") , T("Employee")));

localStorage.setItem('13',TF("{0}", T("Comments")));
localStorage.setItem('14', TF("{0}", T("Employee Presence")));
localStorage.setItem('15', TF("{0}", T("Employee Absence")));
localStorage.setItem('16', TF("{0} {1}", T("Day") , T("Leave")));
localStorage.setItem('17', TF("{0}", T("Sub Admin")));


localStorage.setItem('18',  TF("{0}", T("Security")));
localStorage.setItem('19', TF("{0} {1}", T("Employee") , T("Documents")));
localStorage.setItem('20', T("My Job Description"));
localStorage.setItem('21', TF("{0}", T("Settings")));
localStorage.setItem('22', TF("{0}", T("Organization")));


localStorage.setItem('23',  TF("{0}", T("Security")));
localStorage.setItem('24', TF("{0}", T("Master Department")));
localStorage.setItem('25', T("Point Declaration"));
localStorage.setItem('26', TF("{0}", T("User Role")));
localStorage.setItem('27', TF("{0}", T("Organization")));


localStorage.setItem('28',  TF("{0} {1}", T("Leave") , T("Types")));
localStorage.setItem('29',TF("{0}", T("Admin Apps")));
localStorage.setItem('30', TF("{0}", T("Assets")));
localStorage.setItem('31', TF("{0} {1}", T("Allowance") , T("Type")));
localStorage.setItem('32', TF("{0} {1}", T("Allowance") , T("Code")));

localStorage.setItem('33',TF("{0} {1}", T("Note") , T("Code")));
localStorage.setItem('34',TF("{0} {1}", T("Document") , T("Code")));
localStorage.setItem('35',TF("{0} {1}", T("Comment") , T("Category"))
);
localStorage.setItem('36', TF("{0} {1}", T("Comment") , T("Type")));
localStorage.setItem('37', TF("{0} {1}", T("Blog") , T("Type")));

localStorage.setItem('36', TF("{0} {1}", T("Comment") , T("Type")));
localStorage.setItem('37', TF("{0} {1}", T("Blog") , T("Type")));
localStorage.setItem('38', TF("{0} {1}", T("Comment") , T("Type")));
localStorage.setItem('39', TF("{0} {1}", T("Job") , T("Type")));



localStorage.setItem('40', TF("{0} {1}", T("Annoucement") , T("Type")));
localStorage.setItem('41', TF("{0} {1}", T("Course") , T("Type")));

localStorage.setItem('42', TF("{0}", T("Payroll Line Entry Admin")));
localStorage.setItem('43', TF("{0} {1}", T("Job") , T("Type")));
localStorage.setItem('44', TF("{0}", T("Public Holidays")));
localStorage.setItem('45', TF("{0}", T("Master Department")));

localStorage.setItem('46', TF("{0}", T("Point Declaration")));
localStorage.setItem('47', TF("{0}", T("Job Description")));
localStorage.setItem('48',TF("{0}", T("User Role")));
localStorage.setItem('49',TF("{0} {1}", T("Admin") , T("Apps")));

localStorage.setItem('50',TF("{0}", T("Assets")));
localStorage.setItem('51', TF("{0} {1}", T("Allowance") , T("Type")));
localStorage.setItem('52',TF("{0} {1}", T("Allowance") , T("Code")));
localStorage.setItem('53',TF("{0} {1}", T("Note") , T("Code")));


localStorage.setItem('54', TF("{0} {1}", T("Document") , T("Type")));
localStorage.setItem('55', TF("{0} {1}", T("Employee") , T("Groups")));
localStorage.setItem('56',TF("{0} {1}", T("Comment") , T("Category")));
localStorage.setItem('57',TF("{0} {1}", T("Comment") , T("Type")));

localStorage.setItem('58', TF("{0} {1}", T("Blog") , T("Type")));
localStorage.setItem('59', TF("{0} {1}", T("Job") , T("Type")));
localStorage.setItem('60',TF("{0} {1}", T("Annoucement") , T("Type")));
localStorage.setItem('61',TF("{0} {1}", T("Course") , T("Type")));




localStorage.setItem('62', TF("{0}", T("Employee")));
localStorage.setItem('63', TF("{0}", T("Employee Bulk Import")));
localStorage.setItem('64',TF("{0}", T("Attendance")));
localStorage.setItem('65',TF("{0}", T("Guests")));

localStorage.setItem('66', TF("{0}", T("Check In Out")));
localStorage.setItem('67', TF("{0}", T("Incident Report")));
localStorage.setItem('68',TF("{0}", T("Guests")));
localStorage.setItem('69',TF("{0} {1}", T("Course") , T("Type")));

localStorage.setItem('70',TF("{0}", T("Projects")));

localStorage.setItem('71',TF("{0}", T("Tasks")));

localStorage.setItem('72',TF("{0} {1}", T("Work") , T("Plan")));
localStorage.setItem('73',TF("{0}", T("Employee Presence")));

localStorage.setItem('74', TF("{0}", T("Employee Absence")));
localStorage.setItem('75',TF("{0} {1}", T("My") , T("Leaves")));


localStorage.setItem('76',TF("{0} {1}", T("Work") , T("Plan")));
localStorage.setItem('77',TF("{0}", T("Employee Presence")));

localStorage.setItem('78', TF("{0}", T("Employee Absence")));
localStorage.setItem('75',TF("{0} {1}", T("My") , T("Leaves")));
localStorage.setItem('76',TF("{0} {1}", T("Day") , T("Leave")));
localStorage.setItem('77',TF("{0}", T("Announcements")));

localStorage.setItem('79', TF("{0}", T("Promotions")));
localStorage.setItem('80',TF("{0} {1}", T("My") , T("Leaves")));
localStorage.setItem('81',TF("{0} {1}", T("Day") , T("Leave")));
localStorage.setItem('82',TF("{0}", T("Announcements")));
localStorage.setItem('83',TF("{0} {1}", T("Admin") , T("Apps")));


localStorage.setItem('84',TF("{0}", T("Payroll Line Entry")));
localStorage.setItem('85',TF("{0}", T("Banners")));
localStorage.setItem('86',TF("{0} {1}", T("Employee") , T("List")));
localStorage.setItem('87', TF("{0} {1} {2}", T("Incident") , T("Report"),T("List")));
localStorage.setItem('88', TF("{0} {1}", T("Employee") , T("Chat")));
localStorage.setItem('89',  TF("{0}", T("Messages")));

localStorage.setItem('90', TF("{0} {1}", T("Employee") , T("Training")));
localStorage.setItem('91',TF("{0}", T("Course")));
localStorage.setItem('92',TF("{0}", T("Training")));

localStorage.setItem('93',TF("{0}", T("Blogs")));
localStorage.setItem('94',TF("{0} {1}", T("Organization") , T("Blogs")));

localStorage.setItem('96',TF("{0}", T("SignUp Requests")));
localStorage.setItem('95',TF("{0} {1}", T("User") , T("Role")));

localStorage.setItem('97',TF("{0} {1}", T("HR") , T("Admin")));
localStorage.setItem('98',TF("{0} {1}", T("My profile")));


localStorage.setItem('99',TF("{0}", T("Employee Career")));
localStorage.setItem('100',TF("{0} {1}", T("User") , T("Role")));

localStorage.setItem('101',TF("{0} {1}", T("HR") , T("Admin")));
localStorage.setItem('102',TF("{0}", T("Employee Career")));


localStorage.setItem('103',TF("{0}", T("All Job Post")));
localStorage.setItem('104',TF("{0}", T("Application List")));

localStorage.setItem('105',TF("{0}", T("Employee Bulk Import")));
localStorage.setItem('106',TF("{0}", T("Manage Shifts")));

localStorage.setItem('107',TF("{0}", T("All Shifts")));
localStorage.setItem('108',TF("{0}", T("Shift Scheduler")));
localStorage.setItem('109',TF("{0}", T("Shift Scheduler History")));
localStorage.setItem('110',TF("{0}", T("Shift Scheduler Import")));


localStorage.setItem('111',TF("{0}", T("Payroll")));
localStorage.setItem('112',TF("{0}", T("Manage Tax Brackets")));
localStorage.setItem('113',TF("{0}", T("Payslips")));
localStorage.setItem('114', TF("{0}", T("Pay Codes")));


localStorage.setItem('115',TF("{0} {1}", T("My") , T("Shift Schedule")));
localStorage.setItem('116',TF("{0} {1}", T("My") , T("Payslips")));
localStorage.setItem('117',TF("{0} {1}", T("Access") , T("Code")));
localStorage.setItem('118',TF("{0} {1}", T("My") , T("points")));
localStorage.setItem('119',TF("{0} {1}", T("My") , T("Assests")));
localStorage.setItem('120',TF("{0}", T("Shop")));
localStorage.setItem('121',TF("{0} {1}", T("My") , T("Documents")));
localStorage.setItem('122',TF("{0}", T("Super User")));
localStorage.setItem('123',TF("{0} {1}", T("Employee"),T("Shop")));
localStorage.setItem('124',TF("{0} {1}", T("Product"),T("Category")));
localStorage.setItem('127', TF("{0}", T("Orders")));
localStorage.setItem('125',TF("{0}", T("Products")));
localStorage.setItem('126',TF("{0}", T("All Job Posts")));


    

    


    

    


    


    

    





















    




    

    


    
    




    


   
    


   


    


    

  



   





    













    









   











    









    

    





















    
//alert(name);
// alert();
//     window.addEventListener('offline', function(e) { alert('offline'); });

// window.addEventListener('online', function(e) { alert('online'); });
//$("#first_name").val();

    var pro_package= localStorage.getItem('package');
    var is_super_user= localStorage.getItem('is_super_user');
    this.state.pro_package = pro_package;

var pro_zone_logo_logo;
    if(pro_package == '1')
    {
      pro_zone_logo_logo= myConstClass.SITE_URL+`img/Work-Zone-Pro-logo11.png`;
      $("#pro_logo").attr("src",pro_zone_logo_logo);
    }
    else
    {
      pro_zone_logo_logo= myConstClass.SITE_URL+`img/Work-Zone-Plus-logo.png`;
      $("#pro_logo").attr("src",pro_zone_logo_logo);
    }



let ten_path;
    var url = window.location.pathname;
 
    var str = url.split("/");
    ten_path = '/'+str[1]+'/'+ str[2]; 
    //alert(ten_path+'/guests');
     /* Open collapse element that matches current url */
     const pathName = this.props.location.pathname;







  //    if(pathName==ten_path+'/dashboard')
  //   {
  //    alert('hii');
  //   //  const isActive = false;
  //   // // const isOpen = route.open;
  //   // // const isHome = route.containsHome && pathName === "/" ? true : false;
  //   // /// alert(isActive);
  //   //  this.setState(() => ({
  //   //    [index]: isActive //|| isOpen  // || isHome
  //   //  }));
  //   $("div").removeClass("collapse show");
  //   $("div").addClass("collapse");
  //  // $(".collapse show")
  //   }
    var login_id= localStorage.getItem('login_id');
    var login_type= localStorage.getItem('login_type');
      var admin_id= localStorage.getItem('uname'); 
    var pro_package= localStorage.getItem('package');
    this.state.pro_package = pro_package;
    // alert(pro_package);
    //   if(login_type == 'hr_admin' || login_type == 'super_admin'){
    //   fetch(myConstClass.BASE_URL+'user/getProfileInfo/'+login_id)
    //   .then(response => response.json())
    //   .then(response =>{console.log(response);this.setState({empData: response});})
    //   //  alert(empData);
    //   .catch(err => console.log(err))
    // }
    // else{
    //   fetch(myConstClass.BASE_URL+'user/getUserInfoE/'+login_id)
    //   .then(response => response.json())
    //   .then(response =>{console.log(response);this.setState({empData: response});})
    //   //  alert(empData);
    //   .catch(err => console.log(err))
  
    
    // }
    var is_super_user= localStorage.getItem('is_super_user');



if(login_type == 'hr_admin' && is_super_user=='0' || login_type == 'super_admin'){
    fetch(myConstClass.BASE_URL+'user/getProfileInfo/'+login_id)
    .then(response => response.json())
    .then(response =>{console.log(response);this.setState({empData: response});})
    //  alert(empData);
    .catch(err => console.log(err))
  }
  else if(login_type == 'sub_admin' && is_super_user!='0'){
    fetch(myConstClass.BASE_URL+'user/getSuperAdminStaffInfo/'+is_super_user)
    .then(response => response.json())
    .then(response =>{console.log(response);this.setState({empData: response});})
    //  alert(empData);
    .catch(err => console.log(err))
  }


  else{
    fetch(myConstClass.BASE_URL+'user/getUserInfoE/'+login_id)
    .then(response => response.json())
    .then(response =>{console.log(response);this.setState({empData: response});})
    //  alert(empData);
    .catch(err => console.log(err))

  
  }
  }

  render() { 
    const { sidebar,pro_package } = this.props;
    var admin_id= localStorage.getItem('uname');
    //var pro_package= localStorage.getItem('package');
    const {empData} = this.state;
    let profile_img,profile_name;

    let c_path;
    let c_icon;
    let c_name;
    let c_badgtext;
    let c_badgcolor;
    let c_main;
	let pro_zone_logo_logo;
      if(empData){
        if(empData.image == '' || empData.image == null && empData.image!=''){
          profile_img=  myConstClass.DOCS_URL+`img/profile.png`;
          profile_name=empData.first_name+' '+empData.last_name;  
        } else {
           profile_img =myConstClass.DOCS_URL+`uploads/profile_images_hr/`+admin_id+`/`+empData.image;
		       profile_name=empData.first_name+' '+empData.last_name;
          // profile_img =`http://localhost/workzone/backend/uploads/banners/`+empData.image;
        }
      } else {
        profile_img=  myConstClass.DOCS_URL+`img/profile.png`; 
		    profile_name='Name';
      }
  
  if(pro_package == '1')
  {
	  pro_zone_logo_logo= myConstClass.SITE_URL+`img/Work-Zone-Pro-logo11.png`;
  }
  else
  {
	  pro_zone_logo_logo= myConstClass.SITE_URL+`img/Work-Zone-Plus-logo.png`;
  }
  
  
  
  
  
  
  
  
  

  
    var admin_id= localStorage.getItem('uname');
    
    return (
      <nav
        className={classNames(
          "sidebar",
          sidebar.isOpen || "toggled",
          !sidebar.isOnRight || "sidebar-right"
        )}
      >
       <div className="sidebar-content">
        <div class="ScrollStyle_sidebar" id="vertical_scroll_sidebar">
		
		  
       <Link to={'/'+myConstClass.TENANT_PAGE_PATH+"dashboard"} > <img class="dashboar-plus-logo top_logo" src={logo}  style={{width: '40px',height:'40px'}}/>  <img class="dashboar-plus-logo" src={pro_zone_logo_logo} id="pro_logo"  style={{width: '187px',height:'46px'}}/>
       
       
      
       </Link>
		
       {/* <LanguageList Language={localStorage.getItem("lang")} style={{display:'none'}}/> */}
          <div className="sidebar-user">
            <img
              // src={avatar}
              src={profile_img}
              className="img-fluid rounded-circle mb-2"
              alt="Linda Miller"
            />
            <div className="font-weight-bold">{profile_name}</div>
          </div>
          <ul className="sidebar-nav">
            {routes.map((category, index) => {
           //  label1=<label>{category.name}</label>;
              if(admin_id==0){ 
                if(category.path == 'x'){
                 
                  c_main ='';
                } else {


                  c_main =   category.children ? (
                    <SidebarCategory
                      name={category.name}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                      icon={category.icon}
                      to={category.path}
                      isOpen={this.state[index]}
                      onClick={() => this.toggle(index)}
                    >
  
                      {category.children.map((route, index) => (
//  route.path!='x' ? 

//                       (  
                      <SidebarItem
                          key={index}
                          name={route.name}
                          to={route.path}
                          badgeColor={route.badgeColor}
                          badgeText={route.badgeText}
                        />
                    //   ) :
                    // ''
                      
 

                      ))}
                    </SidebarCategory>
                  ) : (
                    <SidebarItem
                    name={category.name}
                    to={category.path}
                    icon={category.icon}
                    badgeColor={category.badgeColor}
                    badgeText={category.badgeText}
                  />
                  ) ;
                }
               
              } else {
                if(category.path == 'x'){
                  c_main ='';
                } else {
                c_main =   category.children ? (
                  <SidebarCategory
                    name={category.name}
                    badgeColor={category.badgeColor}
                    badgeText={category.badgeText}
                    icon={category.icon}
                    to={category.path}
                    isOpen={this.state[index]}
                    onClick={() => this.toggle(index)}
                  >

                    {category.children.map((route, index) => (
                      // <SidebarItem
                      //   key={index}
                      //   name={route.name}
                      //   to={route.path}
                      //   badgeColor={route.badgeColor}
                      //   badgeText={route.badgeText}
                      // />
                      // route.path!='x' ? 

                      // (  
                        <SidebarItem
                          key={index}
                          name={route.name}
                          to={route.path}
                          badgeColor={route.badgeColor}
                          badgeText={route.badgeText}
                        />
                    //   ) :
                    // ''
                    ))}
                  </SidebarCategory>
                ) : (
                  <SidebarItem
                  name={category.name}
                  to={category.path}
                  icon={category.icon}
                  badgeColor={category.badgeColor}
                  badgeText={category.badgeText}
                />
                ) ;
                }
                // c_name =  category.name;
                // c_badgcolor = category.badgeColor;
                // c_badgtext = category.badgeText;
                // c_icon =  category.icon;
                // c_path = category.path;
              }
              return (
                <React.Fragment key={index}>
                  {category.header ? (
                    <li className="sidebar-header">{category.header}</li>
                  ) : null}

                 {c_main}
                </React.Fragment>
              );
            })}
          </ul><br/>
          {/* <p className="mb-0 copyright">
            &copy; {new Date().getFullYear()} -{" "}
            <Link to="dashboard" className="text-muted">
              Work Zone Plus
            </Link>
          </p> */}
        </div>
      
        </div>
        
      </nav>
      
      
    );
  }
   }

export default withRouter(
  connect(store => ({
    sidebar: store.sidebar
  }))(Sidebar)
);
